import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CoverImagesHelper } from 'src/app/shared/helpers/cover-images.helper';
import { TranslateService } from '@ngx-translate/core';
import { ShopCartProduct, producShippingCost, productLicense } from '../../models/shop-cart-product.model';
import { ShopCartService } from '../../services/shop-cart.service';
import { Subscription } from 'rxjs';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { throws } from 'assert';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-shop-cart-book',
  templateUrl: './shop-cart-book.component.html',
  styleUrls: ['./shop-cart-book.component.scss']
})
export class ShopCartBookComponent {

  private _updateSubscription: Subscription | undefined;

  @Input() cartProduct: ShopCartProduct;
  @Output() deleteProductEvent = new EventEmitter<number>();
  productLicense: productLicense;
  productShippingCost: producShippingCost [] = [];
  shippingPrice: string;
  totalShippingCost: number;
  countryCode: string | null = null; // Para almacenar el valor de countryCode desde el sessionStorage
  filteredData: any[] = []; // Array para almacenar los datos filtrados
  
  readonly updateQuantityValueObserver = {
    next: (data: CommonResponse<any>) => this.updateQuantityValueNext(data),
    error: (error: CommonResponse<any>) => this.updateQuantityValueError(error)
  };

  readonly getLicenceObserver = {
    next: (data: CommonResponse<any>) => this.getLicenceTypeNext(data),
    error: (error: CommonResponse<any>) => this.getLicenceTypeError(error)
  };

  readonly getShippingPriceObserver = {
    next: (data: CommonResponse<any>) => this.getShippingPriceNext(data),
    error: (error: CommonResponse<any>) => this.getShippingPriceError(error)
  };

  constructor(private _translateService: TranslateService,
    private _pageLoadingService: PageLoadingService,
    private _shopCartService: ShopCartService,
    private _snackBarService: SnackbarService) {}

  ngOnInit(): void {
    this._shopCartService.getProductLicense(this.cartProduct.product.productId)
      .subscribe(this.getLicenceObserver);

      this._shopCartService.getProductShippingCost(this.cartProduct.product.productId)
      .subscribe(this.getShippingPriceObserver);
      this.getDataCountryCodeSessionStorage();
  }

  getDataCountryCodeSessionStorage() {
    this.countryCode = localStorage.getItem('countryCode');
    
    // if (sessionData) {
    //   // Parsear el JSON
    //   const userData = JSON.parse(sessionData);
      
    //   // Acceder a countryCode dentro de la propiedad country
    //   this.countryCode = userData?.country?.countryCode ?? null;

    //   console.log('Country Code:', this.countryCode);
    // } else {
    //   console.log('No hay datos en sessionStorage');
    // }
  }

  updateQuantityValueNext(data: CommonResponse<any>) {
    this._updateSubscription?.unsubscribe();
    this._pageLoadingService.hideLoadingGif();
  }

  updateQuantityValueError(error: CommonResponse<any>) {
    this._updateSubscription?.unsubscribe();
    this._pageLoadingService.hideLoadingGif();
  }

  getBookTitleByLanguage() {
    const lang = this._translateService.currentLang;

    if (this.cartProduct != null && this.cartProduct != undefined) {
      return this.cartProduct.product.productGeneralInfo.find((x) => x.language.languageIsoCode == lang)?.title;
    } else {
      return 'empty resume';
    }
  }

  getCurrentDate() {
    if (this._translateService.currentLang == 'es') {
      const date = new Date();
      const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const locale = 'es-CO';
      return date.toLocaleDateString(locale, options);

    }else{
      const date = new Date();
      const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const locale = 'en-US';
      return date.toLocaleDateString(locale, options);
    }
  }

  getLicenceTypeNext(data : CommonResponse<any>) {
    this.productLicense = data.data;
  }

  getLicenceTypeError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    let storedCountryName = localStorage.getItem('countryName');
    if (error.statusCode == 404) {
      if (storedCountryName && storedCountryName.toLowerCase() === 'españa') {
        // this._snackBarService.openStandardSnackBar(
        //   'getCartProductsError404_spain'
        // );
      } else {
        // this._snackBarService.openStandardSnackBar('getCartProductsError404');
      }
    } else {
      if (storedCountryName && storedCountryName.toLowerCase() === 'españa') {
        this._snackBarService.openStandardSnackBar(
          'getCartProductsError_spain'
        );
      } else {
        this._snackBarService.openStandardSnackBar('getCartProductsError');
      }
    }
  }

  getShippingPriceNext(data: CommonResponse<any>) {
    this.productShippingCost = data.data
    this.filteredData = data.data.filter((item:any)=> item.countryCode === this.countryCode); //cambiar el dato quemado por this.countryCode
    //console.log('data filtrado de shopcartbook:',this.filteredData);

    if(this.filteredData.length > 0){
      //console.log('entro a if de shopcartbook');
      if(this.filteredData[0].price != null) {
        this.shippingPrice = this.filteredData[0].price;
        
        this.totalShippingCost = parseFloat(this.shippingPrice = this.filteredData[0].price);
        this.cartProduct.shippingPrice = this.totalShippingCost * this.cartProduct.quantity;

      }
    }else {
      //console.log('entro a else de shopcartbook')
      this.totalShippingCost = 0.0;
      this.cartProduct.shippingPrice = this.totalShippingCost

    }


  }

  getShippingPriceError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    let storedCountryName = localStorage.getItem('countryName');
    if (error.statusCode == 404) {
      if (storedCountryName && storedCountryName.toLowerCase() === 'españa') {
        this._snackBarService.openStandardSnackBar(
          'getCartProductsError404_spain'
        );
      } else {
        this._snackBarService.openStandardSnackBar('getCartProductsError404');
      }
    } else {
      if (storedCountryName && storedCountryName.toLowerCase() === 'españa') {
        this._snackBarService.openStandardSnackBar(
          'getCartProductsError_spain'
        );
      } else {
        this._snackBarService.openStandardSnackBar('getCartProductsError');
      }
    }
  }

  getDueDate() {
  
    if (this._translateService.currentLang == 'es') {
      const event = new Date();

      const yearsToAdd = this.productLicense?.validityMonths / 12;
      const monthsToAdd = this.productLicense?.validityMonths;
      event.setFullYear(event.getFullYear(), event.getMonth() + monthsToAdd);

      const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const locale = 'es-CO';
      return event.toLocaleDateString(locale, options);
    }
    else {
      const event = new Date();
      const yearsToAdd = this.productLicense?.validityMonths / 12;
      const monthsToAdd = this.productLicense?.validityMonths % 12;
      event.setFullYear(event.getFullYear() + yearsToAdd, event.getMonth() + monthsToAdd);
      const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const locale = 'en-US';
      return event.toLocaleDateString(locale, options);
    }
  }

  deleteItem() {
    this.deleteProductEvent.emit(this.cartProduct.product.productId);
  }

  getCoverImageUrl(imageName: string) {
    return CoverImagesHelper.getCoverImageUrl(imageName);
  }

  getProductMarketCode() {
    if(this.cartProduct.product.book !== null && this.cartProduct.product.book !== undefined) {
      return this.cartProduct.product.book.isbn;
    }

    if(this.cartProduct.product.magazine !== null && this.cartProduct.product.magazine !== undefined) {
      return this.cartProduct.product.magazine.issn;
    }

    return "";
  }

  getUnitPriceAmount() {
    return Number(this.cartProduct.price.amount) + Number(this.cartProduct.price.taxes);
  }

  getUnitPriceAmountWithoutTaxes() {
    return Number(this.cartProduct.price.amount);
  }

  getTotalAmount() {
    return (Number(this.cartProduct.price.amount) + Number(this.cartProduct.price.taxes)) * this.cartProduct.quantity;
  }

  getTotalAmountWithoutTaxes() {
    return Number(this.cartProduct.price.amount) * this.cartProduct.quantity;
  }

  getTotalAmountWithTaxes(){
    console.log('cartProduct:------>',this.cartProduct);
    return (Number(this.cartProduct.price.amount) + Number(this.cartProduct.price.taxes) + Number(this.cartProduct.shippingPrice) ) * this.cartProduct.quantity;
  }


  addOne() {
    this._pageLoadingService.showLoadingGif();
    this.cartProduct.quantity += 1;
    this._updateSubscription = this._shopCartService.updateProductQuantityOnShopCart(this.cartProduct.product.productId, this.cartProduct.quantity)?.subscribe(this.updateQuantityValueObserver);
    if(this._updateSubscription == null) {
      this._pageLoadingService.hideLoadingGif();
    }
  }

  removeOne() {
    let newValue = this.cartProduct.quantity - 1;

    if(newValue >= 1) {
      this._pageLoadingService.showLoadingGif();
      this.cartProduct.quantity = newValue;
      this._updateSubscription = this._shopCartService.updateProductQuantityOnShopCart(this.cartProduct.product.productId, newValue)?.subscribe(this.updateQuantityValueObserver);

      if(this._updateSubscription == null) {
        this._pageLoadingService.hideLoadingGif();
      }
    }
  }
}
