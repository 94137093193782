<div class="row p-1 justify-content-end">
  <div class="col text-center" *ngIf="isLogged()"><h4>Page title</h4></div>
  <div class="col-2 align-middle" *ngIf="isLogged()">
    <div class="dropdown">
      <div class="btn-group">
        <a class="text-black text-decoration-none dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
          user name
        </a>
        <ul class="dropdown-menu" aria-labelledby="defaultDropdown">
          <li><a class="dropdown-item" href="#">Log out</a></li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-2">
    <div class="dropdown" *ngIf="isLogged()">
      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownLanguage" data-bs-toggle="dropdown" aria-expanded="false">
        <span class="fi fi-{{selectedLanguage}}"></span>&nbsp;&nbsp;{{selectedLanguage}}
      </button>
      <ul class="language-menu dropdown-menu" aria-labelledby="dropdownLanguage">
        <li *ngFor="let lang of applicationLanguages">
          <a class="dropdown-item" (click)="selectLang($event, lang)" href=""><span class="fi fi-{{lang}}"></span>&nbsp;{{lang}}</a>
        </li>
      </ul>
    </div>
  </div>
</div>
