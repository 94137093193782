import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';
import { CookieTokenService } from 'src/app/auth/services/cookie-token.service';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryProductService {
  private dataSubject = new BehaviorSubject<string>('');

  constructor(
    private _httpClient: HttpClient,
    private _cookieTokenService: CookieTokenService,) { }

  getCategory() {

    const headers = new HttpHeaders().set('Authorization', `${env.token_type} ${this._cookieTokenService.getCookieToken()}`);
    const editorial = 2
    return this._httpClient.get(`${env.url_api}/${env.api_version}/editorial/categories/${editorial}`, { observe: 'response' as 'response', headers: headers})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

  updateCategory(categoryId: number, data: { isDelete: number }): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `${env.token_type} ${this._cookieTokenService.getCookieToken()}`);

    return this._httpClient
      .put(`${env.url_api}/${env.api_version}/update/${categoryId}`, data, { headers })
      .pipe(
        catchError((error) => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: any) => ResponseHelper.generateCommonResponse(response))
      );
  }

  deleteCategory(categoryId: number) {
    
    return this._httpClient.delete(`${env.url_api}/${env.api_version}/category/${categoryId}`, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }
}
