<div class="container mt-5">
  <h2 class="text-center">{{'payment-report.title' | translate}}</h2>

  <div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort class="table table-striped col-1">

      <!-- Payment ID Column -->
      <ng-container matColumnDef="paymentDetailsId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'payment-report.paymentId' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.paymentDetailsId}} </td>
      </ng-container>

      <!-- Amount Column -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'payment-report.amount' | translate}} </th>
        <td mat-cell *matCellDef="let element">{{ formatAmount(element.amount, element.order_details.currency) }}</td>
      </ng-container>

      <!-- Amount Column -->
      <!-- <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'payment-report.amount' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
      </ng-container> -->


      <!-- Provider Column -->
      <ng-container matColumnDef="provider">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'payment-report.provider' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.provider}} </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'payment-report.status' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
      </ng-container>

      <!-- Order ID Column -->
      <ng-container matColumnDef="orderDetailsId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'payment-report.orderId' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.orderDetailsId}} </td>
      </ng-container>

      <!-- User ID Column -->
      <!-- <ng-container matColumnDef="userId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> User ID </th>
          <td mat-cell *matCellDef="let element"> {{element.order_details.user.userId}} </td>
        </ng-container> -->

      <!-- User Email Column -->
      <ng-container matColumnDef="userEmail">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'payment-report.email' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.order_details.user.email}} </td>
      </ng-container>

      <!-- User Countries Column -->
      <ng-container matColumnDef="userCountries">
        <th mat-header-cell *matHeaderCellDef> {{'payment-report.country' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{'country_name.'+ element.order_details.country_general_info.countryCode | translate}} </td>
      </ng-container>

      <!-- Order details Column -->
      <ng-container matColumnDef="paymentProducts">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'payment-report.moreDetails' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="btnDetails">
          <div class="addNew" data-toggle="modal" data-target="#addCategoryModal"
            (click)="showMoreDetails(element.paymentDetailsId)">
            <svg xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
              <path
                d="M14 2.2C22.5-1.7 32.5-.3 39.6 5.8L80 40.4 120.4 5.8c9-7.7 22.3-7.7 31.2 0L192 40.4 232.4 5.8c9-7.7 22.3-7.7 31.2 0L304 40.4 344.4 5.8c7.1-6.1 17.1-7.5 25.6-3.6s14 12.4 14 21.8V488c0 9.4-5.5 17.9-14 21.8s-18.5 2.5-25.6-3.6L304 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L192 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L80 471.6 39.6 506.2c-7.1 6.1-17.1 7.5-25.6 3.6S0 497.4 0 488V24C0 14.6 5.5 6.1 14 2.2zM96 144c-8.8 0-16 7.2-16 16s7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96zM80 352c0 8.8 7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96c-8.8 0-16 7.2-16 16zM96 240c-8.8 0-16 7.2-16 16s7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96z" />
            </svg>
          </div>
        </td>
      </ng-container>


      <!-- Created At Column -->
      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'payment-report.date' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.created_at}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <div class="d-flex align-items-center mb-3" style="text-size:small">
    <!-- Date Input Fields -->
    <label for="dateInit" class="mr-2">{{ 'payment-report.initDate' | translate }}</label>
    <input id="dateInit" type="date" [(ngModel)]="dateInit" class="form-control mr-3" style="width: 200px;margin-left: 1em;">
    <br>
    <label for="dateEnd" class="mr-2" style="margin-left: 1em;">{{ 'payment-report.endDate' | translate }}</label>
    <input id="dateEnd" type="date" [(ngModel)]="dateEnd" class="form-control mr-3" style="width: 200px; margin: 1em;">
    <br>
    <!-- Download Report Link -->
    <a 
      [href]="generateReportLink()" 
      class="btn btn-primary"
      target="_blank">
      {{ 'payment-report.downloadExcel' | translate }}
    </a>
  </div>

  <div class="paginator-container">
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </div>
</div>