<div class="principalContainer">
  <h2 class="text-center">{{ 'payment-report.productsTitle' | translate }}</h2>
  <div class="container mt-5 d-flex flex-column align-items-center">
    <button class="btn mb-3 btnClose" (click)="closeReport()">X</button>
    <div>
      <table class="styled-table">
        <thead>
          <tr>
            <th>{{ 'payment-report.productName' | translate }}</th>
            <th>{{ 'payment-report.list_product_code' | translate }}</th>
            <th>{{ 'payment-report.productPrice' | translate }}</th>
            <th>{{ 'payment-report.quantity' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let product of productsList">
            <td>{{ product.title }}</td>
            <td>{{ product.interactiveCode }}</td>
            <td>{{ product.amount }}</td>
            <td>{{ product.quantity }}</td>
          </tr>
          <!-- Fila para el total -->
          <tr>
            <td colspan="1" class="text-right"><strong>{{ 'payment-report.total_amount' | translate }}</strong></td>
            <td colspan="1"></td>
            <td colspan="1" class="text-right"><strong>{{ totalAmount }}</strong></td>
            <td colspan="1"></td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Información Combinada -->
    <div class="mt-5 w-100 d-flex align-items-center">
      <table class="styled-table" *ngIf="shippingData">
        <thead>
          <tr>
            <th colspan="2">{{ 'shipping.shipping_information' | translate }}</th>
            <th colspan="2">{{ 'shipping.billing_information' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="shippingDesc">{{ 'shippping_info.shipping_full_name' | translate }}</td>
            <td>{{ shippingData.shipping_fullName }}</td>
            <td class="shippingDesc">{{ 'shippping_info.billing_full_name' | translate }}</td>
            <td>{{ shippingData.billing_fullName }}</td>
          </tr>
          <tr>
            <td class="shippingDesc">{{ 'shippping_info.shipping_email' | translate }}</td>
            <td>{{ shippingData.shipping_email }}</td>
            <td class="shippingDesc">{{ 'shippping_info.billing_email' | translate }}</td>
            <td>{{ shippingData.billing_email }}</td>
          </tr>
          <tr>
            <td class="shippingDesc">{{ 'shippping_info.shipping_country' | translate }}</td>
            <td>{{ shippingData.shipping_country }}</td>
            <td class="shippingDesc">{{ 'shippping_info.billing_country' | translate }}</td>
            <td>{{ shippingData.billing_country }}</td>
          </tr>
          <tr>
            <td class="shippingDesc">{{ 'shippping_info.shipping_address' | translate }}</td>
            <td>{{ shippingData.shipping_address }}</td>
            <td class="shippingDesc">{{ 'shippping_info.billing_address' | translate }}</td>
            <td>{{ shippingData.billing_address }}</td>
          </tr>
          <tr>
            <td class="shippingDesc">{{ 'shippping_info.shipping_id_number' | translate }}</td>
            <td>{{ shippingData.shipping_idNumber }}</td>
            <td class="shippingDesc">{{ 'shippping_info.billing_id_number' | translate }}</td>
            <td>{{ shippingData.billing_idNumber }}</td>
          </tr>
          <!-- <tr *ngIf="!shippingData">
            <td colspan="4">{{ 'payment-report.total_amount' | translate }}</td>
          </tr>
        </tbody> -->
      </table>
    </div>
  </div>
</div>
