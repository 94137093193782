import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Product } from 'src/app/books/models/product.model';
import { Country } from 'src/app/countries/models/country.model';
import { CountryService } from 'src/app/countries/services/country.service';
import { EditorialService } from 'src/app/editorials/services/editorial.service';
import { Language } from 'src/app/languages/models/language.model';
import { LanguageService } from 'src/app/languages/services/language.service';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, forkJoin, map, startWith } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
// import { UserRegister } from '../../models/user-register.model';
import { UserService } from '../../services/user.service';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { UserCreate } from '../../models/create-users.model';
import { User } from '../../models/user.model';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-create-users',
  templateUrl: './create-users.component.html',
  styleUrls: ['./create-users.component.scss']
})
export class CreateUsersComponent {

  createUserForm: FormGroup;
  countries: Country[] = [];
  editableCountries: Country[] = []; // Países válidos para editar
  isAdmin: boolean = false; // Indica si el rol seleccionado es Administrador
  languages: Language[] = [];
  products: Product[] = [];
  productsSelected: Product[] = [];
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  filteredProducts: Observable<Product[]>;
  productCtrl = new FormControl('');
  roles: number[] = [];

  @ViewChild('productInput') productInput: ElementRef<HTMLInputElement>;

  readonly getCountriesObserver = {
    next: (data: CommonResponse<any>) => this.getCountriesNext(data),
    error: (error: CommonResponse<any>) => this.getCountriesError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  readonly getLanguagesObserver = {
    next: (data: CommonResponse<any>) => this.getLanguagesNext(data),
    error: (error: CommonResponse<any>) => this.getLanguagesError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  readonly getAllProductsObserver = {
    next: (data: CommonResponse<any>) => this.getAllProductsNext(data),
    error: (error: CommonResponse<any>) => this.getAllProductsError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  readonly createNewUserObserver = {
    next: (data: CommonResponse<any>) => this.createNewUserNext(data),
    error: (error: CommonResponse<any>) => this.createNewUserError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  constructor(
    public dialogRef: MatDialogRef<CreateUsersComponent>,
    private fb: FormBuilder,
    private _translateService: TranslateService,
    private _countryService: CountryService,
    private _languageService: LanguageService,
    private _editorialService: EditorialService,
    private _userService: UserService,
    private _pageLoadingService: PageLoadingService,
    private _snackbarService: SnackbarService,
    private _dialogRef: MatDialogRef<CreateUsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this._pageLoadingService.showLoadingGif();

    this._countryService.getAllCountries().subscribe(this.getCountriesObserver);
    this._languageService.getAllLanguages().subscribe(this.getLanguagesObserver);
    this._editorialService.getAllproducts(2).subscribe(this.getAllProductsObserver);

    this.createUserForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]], // Validador de email
      password: ['', Validators.required],
      roles: ['', Validators.required],
      countryCode: ['default', Validators.required],
      adminEditableCountries: [], // Campo adicional para países válidos
      defaultLanguageCode: ['default', Validators.required],
      defaultProducts: ['default']
    });
  }

  modalInteractTrue() {
    this.dialogRef.close(true);
  }

  registerUser(regForm: NgForm) {

  }

  getCountriesNext(data: CommonResponse<any>) {
    // Log para validar lo que contiene la respuesta
    console.log('Países obtenidos:', data);

    this.countries = data.data;

    if (this.countries.length > 0) {
      this.createUserForm.get('countryCode')?.setValue(this.countries[0].countryCode);
    } else {
      console.warn('No se encontraron países en la respuesta');
    }
  }


  getCountriesError(error: CommonResponse<any>) {

  }

  getLanguagesNext(data: CommonResponse<any>) {
    this.languages = data.data;

    this.createUserForm.get('defaultLanguageCode')?.setValue(this.languages[0].languageCode);
  }

  getLanguagesError(error: CommonResponse<any>) {

  }

  getAllProductsNext(data: CommonResponse<any>) {
    this.products = Object.values(data.data);

    this.autoCompleteFilteredProducts();

    this.createUserForm.get('defaultProducts')?.setValue(this.products);
  }

  getAllProductsError(error: CommonResponse<any>) {

  }

  onRoleChange(event: MatSelectChange) {
    const selectedRole = event.value;
    this.isAdmin = selectedRole == '2'; // Rol de administrador
    if (!this.isAdmin) {
      this.createUserForm.get('adminEditableCountries')?.setValue(''); // Limpia el campo si no es Admin
    }
  }


  getCountryNameByLanguage(country: Country) {
    return country.country_general_info.find(cgi => cgi.language.languageIsoCode == this._translateService.currentLang)?.name;
  }

  add(event: MatChipInputEvent): void {
    this.autoCompleteFilteredProducts();

    // Clear the input value
    event.chipInput!.clear();
    this.productCtrl.setValue(null);
  }

  /** 
   * Eliminar un producto del selector de productos añadidos
   * @param product  elemento a eliminar del array
   */
  remove(product: any): void {
    const index = this.productsSelected.indexOf(product);

    if (index >= 0) {
      this.productsSelected.splice(index, 1);
    }
  }

  /** 
   * Seleccionar un producto del selector de productos a añadir
   * @param event  evento de tipo object con la información de la opción seleccionada
   */
  selected(event: MatAutocompleteSelectedEvent): void {
    //this.products.push(event.option.value);

    if (!this.productsSelected.includes(event.option.value)) {
      this.productsSelected.push(event.option.value);
      this.productsSelected[this.productsSelected.length - 1].quantity = this.productsSelected[this.productsSelected.length - 1].availability > 0 ? 1 : 0;
    }

    this.autoCompleteFilteredProducts();

    this.productInput.nativeElement.value = '';

    this.productCtrl.setValue(null);
  }

  /** 
   * Cambiar la cantidad a asignar de un producto específico
   * @param event   evento de tipo object con la información de la opción seleccionada
   * @param change  opción de incrementar o disminuir la cantidad del producto
   */
  changeQuantity(event: Product, change: string) {
    change == 'lower' ? event.quantity-- : event.quantity++;
    this.productsSelected.map(product => {
      if (product.productId === event.productId) {
        product.quantity = event.quantity;
        return;
      }
    });
  }

  autoCompleteFilteredProducts() {
    this.filteredProducts = this.productCtrl.valueChanges.pipe(
      startWith(null),
      map((product: string | null) => (product ? this._filter(product) : this.products.slice())
      ),
    );
  }

  private _filter(value: string): Product[] {
    // Verificar si value es una cadena antes de llamar a toLowerCase()
    const filterValue = value && typeof value === 'string' ? value.toLowerCase() : '';
    return this.products.filter(p => p.product_general_info[0].title.toLowerCase().includes(filterValue));
  }

  getProductNameByLanguage(product: Product) {
    const currentLang = this._translateService.currentLang;
    const productTitles = product.product_general_info
      .filter(pgi => pgi.language.languageIsoCode == currentLang);

    if (productTitles.length > 0) {
      return productTitles[0].title;
    }

    return "";
  }

  createNewUserNext(data: CommonResponse<any>) {
    // if (data.statusCode >= 200 && data.statusCode < 300) {
      console.log('entra al if next');
    // }
  }

  createNewUserError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();

    this._snackbarService.openStandardSnackBar(error.errors[0]);
  }

  createNewUserFromAdmin() {
    const productsQuantity = this.productsSelected.map(product => ({
      productId: product.productId,
      quantity: product.quantity
    }));
    this.roles = [];
    if (this.createUserForm.get("roles")?.value == 1) { // Usuario
      this.roles.push(1);
    } else if (this.createUserForm.get("roles")?.value == 2) { // Administrador

      this.roles.push(2);
      this.roles.push(3);
    } else {
      this.roles.push(this.createUserForm.get("roles")?.value);
    }
  
    const newUserRequest: UserCreate = {
      name: this.createUserForm.get("name")?.value,
      email: this.createUserForm.get("email")?.value,
      password: this.createUserForm.get("password")?.value,
      roles: this.roles,
      countryCode: this.createUserForm.get("countryCode")?.value,
      defaultLanguageCode: this.createUserForm.get("defaultLanguageCode")?.value,
      shopCartProducts: productsQuantity
    };

    const emailControl = this.createUserForm.get('email');
    
    // Validar que roles solo contenga números válidos
    const rolesValidos = newUserRequest.roles.filter(role => 
      typeof role === "number" && !isNaN(role)
    );

    // Verificar que todos los valores sean válidos
    if ( (Object.values(newUserRequest).some(value => value === null || value === '' || value === undefined)) || rolesValidos.length === 0) {
      this._snackbarService.openStandardSnackBar('questions_validate');
      return;
    } else if (emailControl?.hasError('email')) {
      this._snackbarService.openStandardSnackBar('emailValidate');
      return;
    }
    this._userService.createNewUserFromAdmin(newUserRequest).subscribe(
      (response) => {
        const typedResponse = response as CommonResponse<User>;
    
        if (typedResponse.statusCode == 201) {
          const userId = typedResponse.data?.userId;
    
          if (userId) {
            // Verificar si hay países seleccionados
            const countriesToAdd = this.createUserForm.get('adminEditableCountries')?.value;
            if (Array.isArray(countriesToAdd) && countriesToAdd.length > 0) {
              // Si hay países, agregar y mostrar mensaje correspondiente
              this.addCountriesToUser(userId, countriesToAdd).subscribe(
                () => {
                  this._snackbarService.openStandardSnackBar('successfullyAddUserAndCountry');
                },
                (error) => {
                  console.error('Error al asociar los países:', error);
                  this._snackbarService.openStandardSnackBar('Usuario creado, pero ocurrió un error al asociar los países');
                }
              );
            } else {
              // Si no hay países, mostrar solo el mensaje de usuario creado
              this._snackbarService.openStandardSnackBar('successfullyAddUser');
            }
          } else {
            // console.error('Error: userId no definido en la respuesta');
          }
        } else {
          this._snackbarService.openStandardSnackBar(`addUserAndCountryError`);
        }

        this._userService.updateUserFromAdmin(this.createUserForm)
          .subscribe(this.createNewUserObserver);
    
        this._dialogRef.close();
      },
      (error) => {
        if (error.statusCode == 409){ // El email ingresado ya esta registrado
          this._snackbarService.openStandardSnackBar('emailExists');
        } else {
          this._snackbarService.openStandardSnackBar('addUserAndCountryError');
        }
      }
    );
  }
  
  addCountriesToUser(userId: number, countriesToAdd: any) {
    // const countriesToAdd = this.createUserForm.get('adminEditableCountries')?.value;
    if (countriesToAdd && countriesToAdd.length > 0) {
      const countryRequests = countriesToAdd.map((country: { countryCode: any }) => {
        if (country && country.countryCode) {
          const request = {
            userId: userId,
            countryCode: country.countryCode,
          };
          return this._countryService.createCountryUser(request);
        } else {
          return null;
        }
      }).filter((req: null) => req !== null); // Filtrar solicitudes nulas
  
      // Usar forkJoin para esperar que todas las solicitudes terminen
      return forkJoin(countryRequests);
    } else {
      return forkJoin([]); // Retornar observable vacío si no hay países
    }
  }
}