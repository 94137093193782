<i class="bi bi-person-plus-fill colorIconAction"></i>
<b class="modalTitleModify">{{'editorialBook.add_title' | translate}}</b>
<hr />

<div mat-dialog-content>
    <form [formGroup]="createUserForm" (ngSubmit)="createNewEditorialBook()">
        <div class="container">

            <!-- Campos restantes del formulario -->
            <div class="row">
                <div class="col-12">
                    <input class="inputSize customInput" type="text" name="name" id="name" formControlName="name"
                        placeholder="{{'auth.register.name' | translate}}">
                    <div *ngIf="createUserForm.get('name')?.invalid && createUserForm.get('name')?.touched"
                        class="error-message">
                        <span>{{ 'users.name_required' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <input class="inputSize customInput" type="text" name="description" id="description" formControlName="description"
                        placeholder="{{'editorialBook.description' | translate}}">
                    <div *ngIf="createUserForm.get('description')?.invalid && createUserForm.get('description')?.touched"
                        class="error-message">
                        <span>{{ 'editorialBook.description_required' | translate }}</span>
                    </div>
                </div>
            </div>

            <!-- Botones del formulario -->
            <div class="loginButtons">
                <button class="sendButton" type="submit" [disabled]="createUserForm.invalid">
                    {{'create_product.save_crp' | translate}}
                </button>
                <button class="closeButton" type="button" [mat-dialog-close]="false">{{'users.cancel' |
                    translate}}</button>
            </div>
        </div>
    </form>
</div>