<i class="bi bi-person-plus-fill colorIconAction"></i>
<b class="modalTitleModify">{{'users.add_title' | translate}}</b>
<hr />
<div mat-dialog-content>
  <form [formGroup]="createUserForm" (ngSubmit)="createNewUserFromAdmin()">
    <div class="container">
      <!-- Selector de Rol al inicio del formulario -->
      <div class="row">
        <div class="col-5 boxInfo">
          <label for="roleSelect">{{ 'users.roles' | translate }}</label>
          <mat-form-field appearance="fill" class="fullWidth">
            <mat-label>{{ 'users.roles' | translate}}</mat-label>
            <mat-select id="roleSelect" formControlName="roles" (selectionChange)="onRoleChange($event)">
              <mat-option [value]="2"> {{ 'users.administrator' | translate }} </mat-option>
              <mat-option [value]="1"> {{ 'users.user' | translate }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <!-- Selector adicional para Administrador -->
      <div class="row" *ngIf="isAdmin">
        <div class="col-6 boxInfo">
          <label for="adminEditableCountries">{{ 'users.validCountriesByUser' | translate }}</label>
          <mat-form-field appearance="fill" class="fullWidth">
            <mat-label>{{'users.validCountriesByUser' | translate}}</mat-label>
            <mat-select id="adminEditableCountries" formControlName="adminEditableCountries" multiple>
              <mat-option *ngFor="let country of countries" [value]="country">
                {{ getCountryNameByLanguage(country) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="createUserForm.get('adminEditableCountries')?.invalid && createUserForm.get('adminEditableCountries')?.touched" class="error-message">
            <span>{{ 'users.countries_required' | translate }}</span>
          </div>
        </div>
      </div>


      <!-- Campos restantes del formulario -->
      <div class="row">
        <div class="col-6 boxInfo">
          <label for="name">{{ 'auth.register.name' | translate }}</label>
          <mat-form-field appearance="fill">
            <mat-label>{{ 'auth.register.name' | translate}}</mat-label>
            <input matInput formControlName="name" id="name" type="text" maxlength="254" />
          </mat-form-field>
        </div>
        <div class="col-6 boxInfo">
          <label for="email">{{ 'auth.register.email' | translate }}</label>
          <mat-form-field appearance="fill">
            <mat-label>{{ 'auth.register.email' | translate}}</mat-label>
            <input matInput formControlName="email" id="email" type="text" maxlength="254" />
          </mat-form-field>
        </div>
      </div>


      <div class="row">
        <div class="col-6 boxInfo">
          <label for="password">{{ 'auth.register.password' | translate }}</label>
          <mat-form-field appearance="fill">
            <mat-label>{{ 'auth.register.password' | translate}}</mat-label>
            <input matInput formControlName="password" id="password" type="password" maxlength="254" />
          </mat-form-field>
        </div>
        <div class="col-6 boxInfo">
          <label for="countryCode">{{ 'create_product.country_crp' | translate }}</label>
          <mat-form-field appearance="fill" class="fullWidth">
            <mat-label>{{ 'create_product.country_crp' | translate}}</mat-label>
            <mat-select id="countryCode" formControlName="countryCode">
              <mat-option *ngFor="let country of countries" [value]="country.countryCode"> {{ getCountryNameByLanguage(country) }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <div class="row">
        <div class="col-6 boxInfo">
          <label for="defaultLanguageCode">{{ 'Questions.language' | translate }}</label>
          <mat-form-field appearance="fill" class="fullWidth">
            <mat-label>{{ 'Questions.language' | translate}}</mat-label>
            <mat-select id="defaultLanguageCode" formControlName="defaultLanguageCode">
              <mat-option *ngFor="let language of languages" [value]="language.languageCode"> {{ language.name }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <!-- Botones del formulario -->
      <div class="loginButtons">
        <button class="sendButton" type="submit"> <!--  [disabled]="createUserForm.invalid" -->
          {{'create_product.save_crp' | translate}}
        </button>
        <button class="closeButton" type="button" [mat-dialog-close]="false">{{'users.cancel' | translate}}</button>
      </div>
    </div>
  </form>
</div>
