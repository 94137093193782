<div class="mainContainer" *ngIf="! isProcessingPayment">
  <div class="principalContainer" [formGroup]="billingForm">
    <!-- <div class="title">{{'shipping.billing_information_title' | translate}}</div> -->
    <h5 class="subtitle">{{'shipping.billing_information' | translate}}</h5>

    <div class="row">
      <div class="col-6 shippingInput">
        <!-- <label for="fullName">{{'shipping.person_type' | translate}}</label> -->
        <label *ngIf="countryCode">{{ 'shipping.' + countryCode + '.person_type' | translate }}</label>
        <mat-form-field appearance="fill" class="dropdown">
          <mat-label>{{ 'shipping.' + countryCode + '.person_type' | translate}}</mat-label>
          <mat-select formControlName="personType" (selectionChange)="onPersonTypeChange($event, 'billing')">
            <mat-option *ngFor="let personType of personTypes" [value]="personType.name">
              {{ personType.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6 shippingInput">
        <label *ngIf="countryCode">{{ 'shipping.' + countryCode + '.document_type' | translate }}</label>
        <mat-form-field appearance="fill" class="dropdown">
          <mat-label>{{ 'shipping.' + countryCode + '.document_type' | translate}}</mat-label>
          <mat-select formControlName="documentType">
            <mat-option *ngFor="let identificationType of identificationTypes" [value]="identificationType.name">
              {{ identificationType.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6 shippingInput">
        <label for="idNumber">{{ 'shipping.' + countryCode + '.identity_document' | translate}}</label>
        <input type="text" formControlName="idNumber" id="idNumber" placeholder="{{ 'shipping.' + countryCode + '.identity_document' | translate}}" class="inputSize">
      </div>
    </div>

    <div class="row">
      <div class="shippingInput">
        <label for="fullName">{{ 'shipping.' + countryCode + '.full_name' | translate}}</label>
        <input type="text" formControlName="fullName" id="fullName" placeholder="{{ 'shipping.' + countryCode + '.full_name' | translate}}" class="inputSize">
      </div>
    </div>

    <div class="row">
      <div class="col-6 shippingInput">
        <label for="country">{{ 'shipping.' + countryCode + '.country' | translate}}</label>
        <!-- <input type="text" formControlName="country" id="country" placeholder="{{ 'shipping.' + countryCode + '.country' | translate}}" class="inputSize"> -->
        <mat-form-field appearance="fill" class="dropdown">
          <mat-label>{{ 'shipping.' + countryCode + '.country' | translate}}</mat-label>
          <mat-select formControlName="country" (selectionChange)="onCountryChange($event, 'billing')">
            <mat-option *ngFor="let country of countriesGlobal" [value]="country.countryName">
              {{ country.countryName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6 shippingInput">
        <label for="dpto">{{ 'shipping.' + countryCode + '.dpto' | translate}}</label>
        <!-- <input type="text" formControlName="dpto" id="dpto" placeholder="{{ 'shipping.' + countryCode + '.dpto' | translate}}" class="inputSize"> -->
        <mat-form-field appearance="fill" class="dropdown">
          <mat-label>{{ 'shipping.' + countryCode + '.dpto' | translate}}</mat-label>
          <mat-select formControlName="dpto" (selectionChange)="onStateChange($event, 'billing')">
            <mat-option *ngFor="let state of statesGlobal" [value]="state.stateName">
              {{ state.stateName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6 shippingInput">
        <label for="city">{{ 'shipping.' + countryCode + '.city' | translate}}</label>
        <!-- <input type="text" formControlName="city" id="city" placeholder="{{ 'shipping.' + countryCode + '.city' | translate}}" class="inputSize"> -->
        <mat-form-field appearance="fill" class="dropdown">
          <mat-label>{{ 'shipping.' + countryCode + '.city' | translate}}</mat-label>
          <mat-select formControlName="city">
            <mat-option *ngFor="let city of citiesGlobal" [value]="city.cityName">
              {{ city.cityName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6 shippingInput">
        <label for="postalCode">{{ 'shipping.' + countryCode + '.postal_code' | translate}}</label>
        <input type="number" formControlName="postalCode" id="postalCode" placeholder="{{ 'shipping.' + countryCode + '.postal_code' | translate}}" class="inputSize">
      </div>
    </div>

    <div class="row">
      <div class="shippingInput">
        <label for="address">{{ 'shipping.' + countryCode + '.address' | translate}}</label>
        <input type="text" formControlName="address" id="address" placeholder="{{ 'shipping.' + countryCode + '.address' | translate}}" class="inputSize">
      </div>
    </div>

    <div class="row">
      <div class="col-6 shippingInput">
        <label for="email">{{ 'shipping.' + countryCode + '.email' | translate}}</label>
        <input type="email" formControlName="email" id="email" placeholder="{{ 'shipping.' + countryCode + '.email' | translate}}" class="inputSize">
      </div>
      <div class="col-6 shippingInput">
        <label for="phoneNumber">{{ 'shipping.' + countryCode + '.phone_number' | translate}}</label>
        <input type="number" formControlName="phoneNumber" id="phoneNumber" placeholder="{{ 'shipping.' + countryCode + '.phone_number' | translate}}" class="inputSize">
      </div>
    </div>
  </div>

  <hr>
  <!-- Datos de despacho -->
  <div class="principalContainer" [formGroup]="shippingForm">
    <h5 class="subtitle">{{'shipping.shipping_information' | translate}}</h5>

    <div class="row">
      <div id="checkContainer">
        <input type="checkbox" id="copyCheckbox" (change)="copyBillingData($event)">
        <!-- [disabled]="shippingForm.invalid" -->
        <label for="copyCheckbox">{{'shipping.fill_in_information_with_billing_data' | translate}}</label>
      </div>
    </div>

    <div class="row">
      <div class="col-6 shippingInput">
        <!-- <label for="fullName">{{'shipping.person_type' | translate}}</label> -->
        <label *ngIf="countryCode">{{ 'shipping.' + countryCode + '.person_type' | translate }}</label>
        <mat-form-field appearance="fill" class="dropdown">
          <mat-label>{{ 'shipping.' + countryCode + '.person_type' | translate}}</mat-label>
          <!-- <mat-select formControlName="shippingPersonType" (selectionChange)="onPersonTypeChange($event, 'shipping')">
            <mat-option *ngFor="let personType of personTypesShipping" [value]="personType">
              {{ personType.name }}
            </mat-option>
          </mat-select> -->
          <mat-select formControlName="shippingPersonType" [compareWith]="comparePersonType" (selectionChange)="onPersonTypeChange($event, 'shipping')">
            <mat-option *ngFor="let personType of personTypesShipping" [value]="personType.name">
              {{ personType.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6 shippingInput">
        <label *ngIf="countryCode">{{ 'shipping.' + countryCode + '.document_type' | translate }}</label>
        <mat-form-field appearance="fill" class="dropdown end">
          <mat-label>{{ 'shipping.' + countryCode + '.document_type' | translate}}</mat-label>
          <mat-select formControlName="shippingDocumentType" [compareWith]="compareDocumentType" >
            <mat-option *ngFor="let identificationType of identificationTypesShipping" [value]="identificationType.name">
              {{ identificationType.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6 shippingInput">
        <label for="shippingIdNumber">{{ 'shipping.' + countryCode + '.identity_document' | translate}}</label>
        <input type="text" formControlName="shippingIdNumber" id="shippingIdNumber" placeholder="{{ 'shipping.' + countryCode + '.identity_document' | translate}}" class="inputSize">
      </div>
    </div>

    <div class="row">
      <div class="shippingInput">
        <label for="shippingFullName">{{ 'shipping.' + countryCode + '.full_name' | translate}}</label>
        <input type="text" formControlName="shippingFullName" id="shippingFullName" placeholder="{{ 'shipping.' + countryCode + '.full_name' | translate}}" class="inputSize">
      </div>
    </div>

    <div class="row">
      <div class="col-6 shippingInput">
        <label for="shippingCountry">{{ 'shipping.' + countryCode + '.country' | translate}}</label>
        <!-- <input type="text" formControlName="shippingCountry" id="shippingCountry" placeholder="{{ 'shipping.' + countryCode + '.country' | translate}}" class="inputSize"> -->
        <mat-form-field appearance="fill" class="dropdown">
          <mat-label>{{ 'shipping.' + countryCode + '.country' | translate}}</mat-label>
          <mat-select formControlName="shippingCountry" [compareWith]="compareCountry" (selectionChange)="onCountryChange($event, 'shipping')">
            <mat-option *ngFor="let country of countriesGlobalShipping" [value]="country.countryName">
              {{ country.countryName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6 shippingInput">
        <label for="shippingDpto">{{ 'shipping.' + countryCode + '.dpto' | translate}}</label>
        <!-- <input type="text" formControlName="shippingDpto" id="shippingDpto" placeholder="{{ 'shipping.' + countryCode + '.dpto' | translate}}" class="inputSize"> -->
        <mat-form-field appearance="fill" class="dropdown">
          <mat-label>{{ 'shipping.' + countryCode + '.dpto' | translate}}</mat-label>
          <mat-select formControlName="shippingDpto" [compareWith]="compareDpto" (selectionChange)="onStateChange($event, 'shipping')">
            <mat-option *ngFor="let state of statesGlobalShipping" [value]="state.stateName">
              {{ state.stateName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6 shippingInput">
        <label for="shippingCity">{{ 'shipping.' + countryCode + '.city' | translate}}</label>
        <!-- <input type="text" formControlName="shippingCity" id="shippingCity" placeholder="{{ 'shipping.' + countryCode + '.city' | translate}}" class="inputSize"> -->
        <mat-form-field appearance="fill" class="dropdown">
          <mat-label>{{ 'shipping.' + countryCode + '.city' | translate}}</mat-label>
          <mat-select formControlName="shippingCity" [compareWith]="compareCity" >
            <mat-option *ngFor="let city of citiesGlobalShipping" [value]="city.cityName">
              {{ city.cityName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6 shippingInput">
        <label for="shippingPostalCode">{{ 'shipping.' + countryCode + '.postal_code' | translate}}</label>
        <input type="number" formControlName="shippingPostalCode" id="shippingPostalCode" placeholder="{{ 'shipping.' + countryCode + '.postal_code' | translate}}" class="inputSize">
      </div>
    </div>

    <div class="row">
      <div class="shippingInput">
        <label for="shippingAddress">{{ 'shipping.' + countryCode + '.address' | translate}}</label>
        <input type="text" formControlName="shippingAddress" id="shippingAddress" placeholder="{{ 'shipping.' + countryCode + '.address' | translate}}" class="inputSize">
      </div>
    </div>

    <div class="row">
      <div class="col-6 shippingInput">
        <label for="shippingEmail">{{ 'shipping.' + countryCode + '.email' | translate}}</label>
        <input type="email" formControlName="shippingEmail" id="shippingEmail" placeholder="{{ 'shipping.' + countryCode + '.email' | translate}}" class="inputSize">
      </div>
      <div class="col-6 shippingInput">
        <label for="shippingPhoneNumber">{{ 'shipping.' + countryCode + '.phone_number' | translate}}</label>
        <input type="number" formControlName="shippingPhoneNumber" id="shippingPhoneNumber" placeholder="{{ 'shipping.' + countryCode + '.phone_number' | translate}}" class="inputSize">
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-6 shippingInput">
        <mat-form-field [ngClass]="{'disabled-input': isShippingFormDisabled}" appearance="fill" class="dropdown end">
          <mat-label>{{'shipping.person_type' | translate}}</mat-label>
          <mat-select formControlName="billingPersonType">
            <mat-option value="person">{{'shipping.natural_person' | translate}}</mat-option>
            <mat-option value="company">{{'shipping.company' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6 shippingInput">
        <label [ngClass]="{'disabled-label': isBillingFormDisabled}" for="billingFullName"> {{'shipping.full_name' |
          translate}} </label>
        <input [ngClass]="{'disabled-input': isBillingFormDisabled}" type="text" formControlName="billingFullName"
          placeholder="{{'shipping.name' | translate}}" class="inputSize">
      </div>
      <div class="col-6 shippingInput">
        <label [ngClass]="{'disabled-label': isBillingFormDisabled}" for="billingIdNumber">
          {{'shipping.identity_document' | translate}}
        </label>
        <input [ngClass]="{'disabled-input': isBillingFormDisabled}" type="number" formControlName="billingIdNumber"
          placeholder="{{'shipping.document' | translate}}" class="inputSize">
      </div>
    </div>

    <div class="row">
      <div class="col-6 shippingInput">
        <label [ngClass]="{'disabled-label': isBillingFormDisabled}" for="billingCountry"> {{'shipping.country' |
          translate}} </label>
        <input [ngClass]="{'disabled-input': isBillingFormDisabled}" type="text" formControlName="billingCountry"
          placeholder="{{'shipping.country' | translate}}" class="inputSize">
      </div>
      <div class="col-6 shippingInput">
        <label [ngClass]="{'disabled-label': isBillingFormDisabled}" for="billingAddress">{{'shipping.address' |
          translate}}</label>
        <input [ngClass]="{'disabled-input': isBillingFormDisabled}" type="text" formControlName="billingAddress"
          placeholder="{{'shipping.address' | translate}}" class="inputSize">
      </div>
    </div>

    <div class="row" id="lastInput">
      <div class="shippingInput">
        <label [ngClass]="{'disabled-label': isBillingFormDisabled}" for="billingEmail">{{'shipping.email' |
          translate}}</label>
        <input [ngClass]="{'disabled-input': isBillingFormDisabled}" type="email" formControlName="billingEmail"
          placeholder="{{'shipping.email' | translate}}" class="inputSize">
      </div>
    </div> -->
    <div>
      <h5 class="subtitle pay" for="">{{'shipping.how_do_you_want_to_pay' | translate}}</h5>
      <ng-container>
        <app-payment-methods [formData]="getCombinedFormData()"></app-payment-methods>
      </ng-container>
    </div>
  </div>
</div>

<hr>

    <!-- <button type="submit" (click)="submitShippingInformation()">Prueba</button> -->

<div *ngIf="isProcessingPayment"
  class="proccesingyourPayment d-flex flex-column justify-content-center align-items-center">
  <div class="title mb-3">{{'shipping.processing_your_payment' | translate}}</div>
  <div class="spinner-border spinner-border-lg text-info mb-3" role="status">
  </div>
</div>