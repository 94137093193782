import { FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit, ElementRef, Inject, ViewChild, Renderer2, RendererStyleFlags2, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { LoginService } from 'src/app/auth/services/logIn.service';
import { Language } from 'src/app/languages/models/language.model';
import { Product } from 'src/app/books/models/product.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { UserRegister } from '../../models/user-register.model';
import { Observable, map, startWith, switchMap, tap } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { EditorialService } from 'src/app/editorials/services/editorial.service';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';

type UserUpdate = {
  userId: number;
  emailData: {
    email: string;
    update: boolean;
  };
  nameData: {
    name: string;
    update: boolean;
  };
  countryCodeData: {
    countryCode: string;
    update: boolean;
  };
  defaultLanguageCodeData: {
    defaultLanguageCode: string;
    update: boolean;
  };
  roleData: {
    roles: number[];
    update: boolean;
  };
  productsSelected: Product[];
};

@Component({
  selector: 'app-edit-user-books',
  templateUrl: './edit-user-books.component.html',
  styleUrls: ['./edit-user-books.component.scss']
})
export class EditUserBooksComponent implements OnInit {

  api_path_img = `${env['images_url_base']}`;
  productsSelected: Product[] =[];
  idUser = 0;
  nameUser: string = "";
  permissions: string[];
  isAdmin = false;
  user: UserRegister;
  languages: Language[] = [];
  products: Product[] = [];
  allProducts: Product[] = [];
  productCtrl = new FormControl('');
  dataSource = new MatTableDataSource<Product>();
  displayedColumns: string[] = ['id', 'title', 'productCode', 'code', 'quantity', 'availability', 'actions'];
  userUpdate = {} as UserUpdate;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  // Formulario
  productForm = new FormGroup({
    product: new FormControl(null), // Control para el selector
  });

  readonly getUserObserver = {
    next: (data: CommonResponse<any>) => this.getUserNext(data),
    error: (error: CommonResponse<any>) => this.getUserError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  readonly getLanguagesObserver = {
    next: (data: CommonResponse<any>) => this.getLanguagesNext(data),
    error: (error: CommonResponse<any>) => this.getLanguagesError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };
  
  private readonly getAllProductsObserver = {
    next: (data: CommonResponse<any>) => this.getAllProductsNext(data),
    error: (error: CommonResponse<any>) => this.getAllProductsError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  private readonly deleteUserProductsObserver = {
    next: (data: CommonResponse<any>) => this.deleteUserProductsNext(data),
    error: (error: CommonResponse<any>) => this.deleteUserProductsError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  private readonly getUserProductsObserver = {
    next: (data: CommonResponse<any>) => this.getUserProductsNext(data),
    error: (error: CommonResponse<any>) => this.getUserProductsError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  readonly updateNewUserObserver = {
    next: (data: CommonResponse<any>) => this.updateNewUserNext(data),
    error: (error: CommonResponse<any>) => this.updateNewUserError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  constructor( private _editorialService: EditorialService,
    private _loginService: LoginService,
    public dialogRef: MatDialogRef<EditUserBooksComponent>,
    private _pageLoadingService: PageLoadingService,
    private _snackbarService: SnackbarService,
    private _translate: TranslateService,
    private _userService: UserService,
    private _dialog: MatDialog,
    private _renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: { userId: number },
  ) {
    this.userUpdate.userId = data.userId;
    this._editorialService.getAllproducts(2).subscribe(this.getAllProductsObserver);
    this._editorialService.getAllproducts(2).pipe(
      tap(() => {
        // Aquí puedes ejecutar código adicional si es necesario antes de la suscripción
      }),
      switchMap(() => {
        return this._userService.getUser(data.userId);
      })
    ).subscribe(this.getUserObserver);
   }

  ngOnInit(): void {
    this._pageLoadingService.showLoadingGif();
    const user = this._loginService.getLoggedUser();
    if (user != null) {
      this.idUser = user.userId;
      this.permissions = user.permissions;
      this.havePermissions(this.permissions);
    }
  }

  modalInteractTrue() {
    this.dialogRef.close(true);
  }

  getLanguagesNext(data: CommonResponse<any>) {
    this.languages = data.data;
  }

  getLanguagesError(error: CommonResponse<any>) {
  }

  getAllProductsNext(data: CommonResponse<any>) {
    this.products = Object.values(data.data);
    this.allProducts = Object.values(data.data);

    if (ResponseHelper.responseDontHaveErrors(data)) {
      setTimeout(() => {
        this.initializePaginator();
        this._pageLoadingService.hideLoadingGif();
      }, 500);
    }
    else {
      this._pageLoadingService.hideLoadingGif();
    }
  }

  getAllProductsError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();

    if (error.statusCode == 404) {
      this.dataSource.data = [];
      this._snackbarService.openStandardSnackBar('getUserProductsError404');
    }
  }

  updateNewUserNext(data: CommonResponse<any>) {
    if (data.statusCode >= 200 && data.statusCode < 300) {
      this.dialogRef.close(true);
      this._snackbarService.openStandardSnackBar('added_products', 'Ok');
    }
  }

  updateNewUserError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this._snackbarService.openStandardSnackBar(error.errors[0]);
  }

  getUserNext(data: CommonResponse<any>) {
    this.user = data.data;
    
    const roles = (this.user as any).roles;
    let selectedRole = '';
    if (roles && Array.isArray(roles)) {
      if (roles.length === 1 && roles[0].roleId === 1) {
          this.userUpdate.roleData = this.userUpdate.roleData || { roles: [], update: false };
          this.userUpdate.roleData.roles.push(1);
          selectedRole = '1'; // Usuario
      } else if ( roles.length > 1 &&
                  roles.some((role: any) => role.roleId === 2) &&
                  roles.some((role: any) => role.roleId === 3)
      ) {
          selectedRole = '2'; // Administrador
          this.userUpdate.roleData = this.userUpdate.roleData || { roles: [], update: false };
          this.userUpdate.roleData.roles.push(2);
          this.userUpdate.roleData.roles.push(3);
      }
      this.userUpdate.roleData.update = false;
    }

    var productsUser: Array<any> = this.user.shopCartProducts;
    // Recorre el array productsUser
    for (const productUser of productsUser) {
      // Busca el producto correspondiente en el array products
      const productMatch = this.products.find(product => product.productId === productUser.productId);

      // Si encuentra una coincidencia
      if (productMatch) {
        // Busca el producto en productsSelected
        const selectedProductIndex = this.productsSelected.findIndex(selectedProduct => selectedProduct.productId === productUser.productId);

        // Si el producto no existe en productsSelected, agrégalo con quantity igual a 1
        if (selectedProductIndex === -1) {
          this.productsSelected.push({ ...productMatch, quantity: 1 });
        } else {
          // Si el producto ya existe en productsSelected, incrementa la cantidad en 1
          this.productsSelected[selectedProductIndex].quantity += 1;
        }
      }
    }
    this.nameUser = this.user.name;
    this.userUpdate.nameData = { name: this.user.name, update: false };
    this.userUpdate.roleData.update = false;
    this.userUpdate.emailData = { email: this.user.email, update: false };
    this.userUpdate.countryCodeData = { countryCode: this.user.countryCode, update: false };
    this.userUpdate.defaultLanguageCodeData = { defaultLanguageCode: this.user.defaultLanguageCode, update: false };
    this.userUpdate.productsSelected = this.productsSelected;

    // ACA EL DATA SOURCE DEBE LLENARSE O IGUALARSE
    this.dataSource.data = this.productsSelected;
    this.cdr.detectChanges(); // Forzar detección de cambios

    this.productCtrl.setValue(null);

    return;
  }

  getUserError(error: CommonResponse<any>) { }

  getUserProductsNext(data: CommonResponse<any>) {
    if (ResponseHelper.responseDontHaveErrors(data)) {
      this.dataSource.data = (data.data as CommonResponse<any>).data;
      setTimeout(() => {
        this.initializePaginator();
        this._pageLoadingService.hideLoadingGif();
      }, 500);
    }
    else {
      this._pageLoadingService.hideLoadingGif();
    }
  }

  getUserProductsError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();

    if (error.statusCode == 404) {
      this.dataSource.data = [];
      this._snackbarService.openStandardSnackBar('getUserProductsError404');
    }
  }

  deleteUserProductsNext(data: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this._snackbarService.openStandardSnackBar('successfullyDeletedProduct');
    this._editorialService.getproducts(2, 1, this.idUser).subscribe(this.getUserProductsObserver);
  }

  deleteUserProductsError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();

    if (error.statusCode == 404) {
      this._snackbarService.openStandardSnackBar('unsuccessfullyDeletedProduct');
    }
    else {
      this._snackbarService.openStandardSnackBar('errorDeletingProduct');
    }
  }

  initializePaginator() {
    this.dataSource.paginator = this.paginator;
  }

  havePermissions(permissions: any) {
    const havePermissions = permissions.find(function (permission: any) {
      return permission === 'update_book';
    });

    if (havePermissions == 'update_book') {
      this.isAdmin = true;
    }
  }

  getBookTitleByLanguage(id: number) {
    const product = this.dataSource.data.find(x => x.productId == id);
    if (product != null && product != undefined) {
      return product.product_general_info.find(x => x.language.languageIsoCode == this._translate.currentLang)?.title;
    }
    else {
      return "empty title";
    }
  }

    getBookTitleByLanguageAllProducts(id: number) {
      const product = this.allProducts.find(x => x.productId == id);
      if (product != null && product != undefined) {
        return product.product_general_info.find(x => x.language.languageIsoCode == this._translate.currentLang)?.title;
      }
      else {
        return "empty title";
      }
    }

  getBookResumeByLanguage(id: number) {
    const product = this.dataSource.data.find(x => x.productId == id);

    if (product != null && product != undefined) {
      return product.product_general_info.find(x => x.language.languageIsoCode == this._translate.currentLang)?.resume;
    }
    else {
      return "empty resume";
    }
  }

  getMultiLanguageText(id: number): string[] {
    const bookLanguages: string[] = [];
    const product = this.dataSource.data.find(x => x.productId === id);
  
    if (product != null && product != undefined) {
      const translations: { [key: string]: { [key: string]: string } } = {
        en: {
          en: "English",
          es: "Spanish",
          pt: "Portuguese"
        },
        es: {
          en: "Inglés",
          es: "Español",
          pt: "Portugués"
        },
        pt: {
          en: "Inglês",
          es: "Espanhol",
          pt: "Português"
        }
      };
  
      const currentLang = this._translate.currentLang;
  
      product.product_general_info.forEach(bc => {
        const isoCode = bc.language.languageIsoCode;

        const langName = translations[currentLang]?.[isoCode] || ";";
        bookLanguages.push(langName);
      });
    }
  
    return bookLanguages;
  }

  increaseQuantity(element: Product): void {
    // Solo aumentar si availability es mayor que 0
    if (element.availability > 0) {
      element.quantity += 1;
      element.availability -= 1; // Reducir disponibilidad
      this.updateDataSource(); // Refrescar el dataSource
    }
  }
  
  decreaseQuantity(element: Product): void {
    // Solo disminuir si quantity es mayor que 1
    if (element.quantity > 1) {
      element.quantity -= 1;
      element.availability += 1; // Incrementar disponibilidad
      this.updateDataSource(); // Refrescar el dataSource
    }
  }
  
  // Refresca el dataSource para reflejar los cambios
  updateDataSource(): void {
    this.dataSource.data = [...this.dataSource.data]; // Crear una nueva referencia para forzar el render
  }

  addProduct() {
    const selectedProduct = this.productForm.get('product')?.value; // Obtener el producto seleccionado
    
    // Verifica la disponibilidad
    if (selectedProduct && selectedProduct.availability > 0) {
      // Verifica si el producto ya está en la lista
      const existingProductIndex = this.productsSelected.findIndex(p => p.productId === selectedProduct.productId);
      
      if (existingProductIndex === -1) {
        // Hacemos una copia del producto seleccionado
        const productToAdd = { ...selectedProduct, quantity: 1 }; // Crea una copia con la cantidad inicial
        productToAdd.availability--; // Disminuye la disponibilidad del producto seleccionado
        
        // Agregamos la copia del producto a la lista de productos seleccionados
        this.productsSelected.push(productToAdd);
        
        // Actualizamos el dataSource con la lista de productos seleccionados
        this.dataSource.data = [...this.productsSelected];  // Asegúrate de hacer una copia

        // Reiniciar el selector de productos
        this.productForm.get('product')?.reset();
      } else {
        this._snackbarService.openStandardSnackBar('product_already_selected', 'Ok');
      }
    }
  }

  deleteProduct(productId: number) {
    this.userUpdate.productsSelected = this.userUpdate.productsSelected.filter(
      product => product.productId !== productId);
    this.productsSelected = this.userUpdate.productsSelected;
    this.dataSource.data = this.productsSelected;
  }

  confirmProducts() {
    this.userUpdate.productsSelected.forEach(productSelected => {
      const matchingProduct = this.allProducts.find(p => p.productId === productSelected.productId);
      if (matchingProduct) {
        const clonedProduct = { ...matchingProduct }; // Clonar el producto
        productSelected.availability = clonedProduct.availability;
      }
    });
    this._userService.updateUserFromAdmin(this.userUpdate).
      subscribe(this.updateNewUserObserver);
  }
  

}
