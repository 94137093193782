import { Component, OnInit } from '@angular/core';
import { FormGroup,FormBuilder,Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-editorials-books',
  templateUrl: './edit-editorials-books.component.html',
  styleUrls: ['./edit-editorials-books.component.scss']
})
export class EditEditorialsBooksComponent implements OnInit {
  editFormEditorials:FormGroup;
  constructor(
        private fb: FormBuilder,
    
  ) { 
        this.editFormEditorials = this.fb.group({
          name: ['', Validators.required],
          description: ['', Validators.required],
        });
  }

  ngOnInit(): void {
  }

  modifyEditorialBook(){
    console.log('entro a modificar editorial:')
  }

}
