interface MenuItem {
  titleKey: string;
  icon: string;
  permission?: string;
  url?: string;
  children?: MenuItem[];
}

const menuItems: MenuItem[] = [
  {
    titleKey: 'menu.books',
    icon: 'bi bi-book-half fa-lg',
    children: [
      {
        titleKey: 'menu.create_books',
        url: '/book/create',
        icon: 'bi bi-plus-circle-fill fa-lg',
        permission: 'create_book'
      },
      // {
      //   titleKey: 'menu.manage_categories',
      //   url: '/book/categories',
      //   icon: 'bi bi-bookmark-plus',
      //   permission: 'create_book'
      // },
      {
        titleKey: 'menu.manage_categories',
        url: '/book/categories',
        icon: 'bi bi-bookmark-plus',
        permission: 'create_book'
      },
      {
        titleKey: 'menu.my_books',
        url: '/book/my-books',
        icon: 'bi bi-list-ul fa-lg',
        permission: 'list_book'
      },
      {
        titleKey: 'menu.manage_editorials',
        url: '/book/editorial-books',
        icon: 'bi bi-journal-bookmark-fill',
        permission: 'create_book'
      },
      // {
      //   titleKey: 'menu.archived_books',
      //   url: '/book/view-archived-books',
      //   icon: 'bi bi-collection-fill fa-lg',
      //   permission: 'list_archived_book'
      // }
    ]
  },
  {
    titleKey: 'menu.shop',
    icon: 'bi bi-bag',
    children: [
      {
        titleKey: 'menu.shop_cart',
        url: '/book/shop-cart',
        icon: 'bi bi-cart-fill fa-lg',
        permission: 'all'
      },
      {
        titleKey: 'menu.go_shop',
        url: '/book/shop',
        icon: 'bi bi-bag fa-lg',
        permission: 'all'
      }
    ]
  },
  {
    titleKey: 'menu.report',
    icon: 'bi bi-file-earmark-text-fill',
    children: [
      {
        titleKey: 'menu.payment_report',
        url: '/report/payment-report',
        icon: 'bi bi-credit-card',
        permission: 'list_all_users'
      }
    ]
  },
  {
    titleKey: 'menu.users',
    icon: 'bi bi-people-fill',
    children: [
      {
        titleKey: 'menu.list_users',
        url: '/users/list',
        icon: 'bi bi-card-list',
        permission: 'list_all_users'
      }
    ]
  },
  {
    titleKey: 'menu.support',
    icon: 'bi bi-gear-fill',
    children: [
      {
        titleKey: 'menu.documents',
        url: '/customer-services/documents',
        icon: 'fa fa-file',
        permission: 'list_all_users'
      },
      {
        titleKey: 'menu.questions',
        url: '/customer-services/questions',
        icon: 'fa fa-question-circle',
        permission: 'list_all_users'
      }
    ]
  }
];

export { MenuItem, menuItems };
