import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { environment as env } from 'src/environments/environment';
import { ResponseHelper } from '../../shared/helpers/response.helper';


@Injectable({
  providedIn: 'root'
})
export class EditorialsBooksService {

  constructor(private _httpClient: HttpClient) {
  }

  getAllEditorials() {

    return this._httpClient.get(`${env.url_api}/${env.api_version}/editorials-books/all`, { observe: 'response' as 'response' })
      .pipe(map((response: HttpResponse<any>) => {
        return ResponseHelper.generateCommonResponse(response);
      }));
  }

  createEditorialBook(newEditorial:any) {
    return this._httpClient.post<HttpResponse<any>>(`${env.url_api}/${env.api_version}/editorials-books/create`, newEditorial, { observe: 'response' })
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {
          return ResponseHelper.generateCommonResponse(response); // Verifica que este retorno sea compatible
        })
      );
  }

  deleteEditorialBook(editorialBookId: number) {
    return this._httpClient.delete(`${env.url_api}/${env.api_version}/editorials-books/delete/${editorialBookId}`, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

}