import { LOCALE_ID, NgModule } from '@angular/core';
import localeCo from '@angular/common/locales/es-CO';
import { BrowserModule } from '@angular/platform-browser';
registerLocaleData(localeCo);

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthModule } from './auth/auth.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BasicLayoutComponent } from './layouts/basic-layout/basic-layout.component';
import { SharedModule } from './shared/shared.module';
import { BooksModule } from './books/books.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from './shared/translation/http-loader.factory';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomHttpRequestInterceptor } from './middleware/custom-http-request-interceptor.interceptor';
import { registerLocaleData } from '@angular/common';
import { UsersModule } from './users/users.module';
import { CountriesModule } from './countries/countries.module';

import { NgxPaginationModule } from 'ngx-pagination';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from './shared/matherial/custom-mat-paginator-intl';
import { PaymentModule } from './payment/payment.module';
import { CookieService } from 'ngx-cookie-service';
import { ReportModule } from './report/report.module';

import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';


@NgModule({
  declarations: [
    AppComponent,
    BasicLayoutComponent,
  ],
  imports: [
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    NgbModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    SharedModule,
    AuthModule,
    PaymentModule,
    BooksModule,
    UsersModule,
    ReportModule,
    CountriesModule,
    AppRoutingModule,
    MatTableModule,
    MatIconModule,
    MatInputModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpRequestInterceptor, multi:true },
    { provide: LOCALE_ID, useValue: 'es-CO'},
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
