<div class="row">
    <div class="tableContainer">
        <div class="addNew" data-toggle="modal" data-target="#addCategoryModal" (click)="addNewEditorialBook()">
            <svg class="addImg" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                <path
                    d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
            </svg>
            {{'editorialBook.add_editorialBook' | translate}}
        </div>
        <table class="table table-striped col-1">
            <thead>
                <tr>
                    <th>{{'editorialBook.id' | translate}}</th>
                    <th>{{'editorialBook.editorialBook_name' | translate}}</th>
                    <th>{{'editorialBook.editorialBook_description' | translate}}</th>
                    <th>{{'editorialBook.actions' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of editorials">
                    <td>{{item.editorialBookId}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.description}}</td>
                    <td>
                        <!-- Editar Editoral -->
                        <!-- <i (click)="editEditorialBook()" class="bi bi-pencil-square"></i>  -->
                        <!-- Eliminar Editorial -->
                        <i (click)="showDeleteEditorialBookConfirmation(item.editorialBookId)" data-toggle="modal" data-target="#modalConfirmation" class="bi bi-trash-fill"></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>