<div class="main">
    <div class="row">
        <!-- Header -->
        <div class="row headerListBook">
            <div class="w-auto">
                <b class="titleListBook">{{'my_books.products_user' | translate}}:</b>
            </div>
            <div class="w-auto userName">
                <p>{{ nameUser }}</p>
            </div>
            <br>
        </div>
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="col">
                        <p class="descriptionViewListBook">
                            {{'my_books.userBookText' | translate}}
                        </p>
                    </div>
                </div>
                <br>
                <div class="row searchProduct" [formGroup]="productForm">
                    <div class="w-auto">
                        <!-- <label for="product" style="padding-left: 0">{{'users.add_product' | translate}}</label> -->
                        <div class="col mb-2" style="margin-bottom: 0 !important;">
                            <mat-form-field appearance="fill" class="dropdown" style="width: 325px;">
                                <mat-label>{{'users.add_product' | translate}}</mat-label>
                                <mat-select formControlName="product">
                                    <mat-option *ngFor="let product of allProducts" [value]="product">
                                        <!-- {{'create_product.' + product.productId | translate}} -->
                                        {{getBookTitleByLanguageAllProducts(product.productId)}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="w-auto">
                        <button class="sendButton addProduct" type="submit" (click)="addProduct()">
                            {{'category.add' | translate}}
                        </button>
                    </div>
                </div>
                <br>
                <div class="row">
                  <!-- <ng-container *ngIf="dataSource.data.length > 0 else elseTemplate"> -->
                  <ng-container *ngIf="dataSource.data.length > 0">

                    <div class="mat-elevation-z8">
                        <table mat-table [dataSource]="dataSource">

                            <!-- id Column -->
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'my_books.book_code_mb' | translate}}</b></th>
                                <td mat-cell *matCellDef="let element" class="colSize"><b class="sizeMaxText">{{element.id}}</b></td>
                            </ng-container>

                             <!-- Title Column -->
                             <ng-container matColumnDef="title">
                                <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'create_product.title_crp' | translate}}</b></th>
                                <td mat-cell *matCellDef="let element" class="shortcolSize"><b class="sizeMaxText">{{getBookTitleByLanguage(element.id)}}</b></td>
                            </ng-container>

                             <!-- Code Column - isbn -->
                             <ng-container matColumnDef="productCode">
                                <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'my_books.list_product_code' | translate}}</b></th>
                                <td mat-cell *matCellDef="let element" class="shortcolSize"><b class="sizeMaxText">{{element.isbn || element.issn}}</b></td>
                            </ng-container>

                            <!-- Product code Column -->
                            <ng-container matColumnDef="code">
                                <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'my_books.book_code_mb' | translate}} </b></th>
                                <td mat-cell *matCellDef="let element" class="shortcolSize"><b class="sizeMaxText">{{element.interactiveCode}}</b></td>
                            </ng-container>

                            <!-- Quantity Column -->
                            <ng-container matColumnDef="quantity">
                                <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'create_product.quantity' | translate}} </b></th>
                                <!-- <td mat-cell *matCellDef="let element" class="colSize"><b class="sizeMaxText">{{element.quantity}}</b></td> -->
                                <td mat-cell *matCellDef="let element" class="colSize quantity">
                                    <button class="buttonQuantityProduct" (click)="decreaseQuantity(element)" [disabled]="element.quantity <= 1">-</button>
                                    {{ element.quantity }}
                                    <button class="buttonQuantityProduct" (click)="increaseQuantity(element)" [disabled]="element.availability == 0">+</button>
                                </td>
                            </ng-container>
                            
                            <!-- Quantity Column -->
                            <ng-container matColumnDef="availability">
                                <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'create_product.availabilty_crp' | translate}} </b></th>
                                <td mat-cell *matCellDef="let element" class="colSize"><b class="sizeMaxText">{{element.availability}}</b></td>
                            </ng-container>

                            <!-- Actions -->
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef class="sizeThead text-center"><b>{{'create_product.actions_crp' | translate}}</b></th>
                                <td mat-cell *matCellDef="let element" class="colSize">
                                    <button mat-raised-button (click)="deleteProduct(element.id)" class="actions m-1">
                                        <i data-toggle="modal" data-target="#modalConfirmation" class="bi bi-trash-fill"></i></button>
                                </td>
                            </ng-container>                  

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                            <!-- Row shown when there is no matching data that will be provided to the wrapper table. -->
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell no-data-centered" colspan="5">{{'create_product.not_products_crp' | translate}}</td>
                            </tr>

                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell no-data-centered" colspan="5">{{'create_product.empty_crp' | translate}}</td>
                            </tr>

                        </table>
                        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                        <!--TO DO: pagination bootstrap

                            <nav aria-label="Page navigation example">
                            <ul class="pagination justify-content-end">
                              <li class="page-item">
                                <a class="page-link" href="#" aria-label="Previous">
                                  <span aria-hidden="true">&laquo;</span>
                                </a>
                              </li>
                              <li class="page-item active"><a class="page-link" href="/book/view-archive-books">1</a></li>
                              <li class="page-item"><a class="page-link" href="#">2</a></li>
                              <li class="page-item"><a class="page-link" href="#">3</a></li>
                              <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next">
                                  <span aria-hidden="true">&raquo;</span>
                                </a>
                              </li>
                            </ul>
                        </nav>    -->
                   </div>
                  </ng-container>
                    <!-- <ng-template #elseTemplate>
                        <br><br><br>
                        <div class="row text-center">
                            <div class="col">
                            <h2>{{"store.not_products" | translate}}</h2>
                            <br>
                            <button class="sendAddBook" [routerLink]="['/book/shop']">{{'store.go_to_store' | translate}}</button>
                            </div>
                        </div>
                    </ng-template> -->
                </div>
                <div class="loginButtons">
                    <button class="sendButton" type="submit" (click)="confirmProducts()">{{'create_product.save_crp' |
                      translate}}</button>
                    <button class="closeButton" type="button" [mat-dialog-close]="false">{{'users.cancel' | translate}}</button>
                  </div>
            </div>
        </div>
    </div>
</div>