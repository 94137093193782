import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ReportRoutingModule } from './report-routing.module';
import { MainViewReportComponent } from './components/main-view-report/main-view-report.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    MainViewReportComponent
  ],
  imports: [
    CommonModule,
    ReportRoutingModule,
    TranslateModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    FormsModule
  ],
  exports: [
  ]
})
export class ReportModule { }
