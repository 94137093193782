<div class="col-12">
  <form [formGroup]="productForm" enctype="multipart/form-data" novalidate class="need">
    <!-- Explicacion de como agregar un libro -->
    <div class="row">
      <div class="col-6 p-2">
        <b class="titleCreateBook">{{'create_product.add_product_crp' | translate}}</b>
      </div>
      <div class="col-12">
        <p class="descriptionViewAddBook">
          {{'create_product.explanation' | translate}}
        </p>
      </div>
    </div>
    <br><br><br>
    <!-- Info libro -->
    <div class="row d-flex justify-content-evenly">
      <div class="col-5">
        <p class="subtitlesAddBook d-flex justify-content-center">{{'create_product.book_info_crp' | translate}}</p>
        <small class="smalltitlesAddbok d-flex justify-content-center">{{'create_product.add_product_info' |
          translate}}</small>
      </div>
      <div class="col-7">
        <div class="accordion accordion-item">
          <h2 class="accordion-header" id="panelsStayOpen-headingOne">
            <!-- Información libro -->
            <a class="accordion-button collapsed addBookButton" type="button" data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false"
              aria-controls="panelsStayOpen-collapseOne"><i class="bi bi-plus-circle-fill sizeIconAdd"></i><b
                class="addBookButton"> {{'create_product.add_crp' | translate}}</b></a>
          </h2>
          <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingOne">
            <div class="accordion-body">

              <div class="container">
                <div class="row">
                  <div class="col md-6">
                    <label for="typeProduct" style="padding-left: 0">{{'create_product.type_product_crp' |
                      translate}}</label>
                    <div class="col mb-2">
                      <mat-form-field appearance="fill" class="dropdown">
                        <mat-label>{{'create_product.type_product_crp' | translate}}</mat-label>
                        <mat-select formControlName="type">
                          <mat-option *ngFor="let type of productType" [value]="type.name">
                            {{'create_product.' + type.name | translate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col md-6">
                    <label for="licence">{{'create_product.licence_crp' | translate}}</label>
                    <div class="col mb-2">
                      <mat-form-field appearance="fill" class="dropdown end">
                        <mat-label>{{'create_product.licence_crp' | translate}}</mat-label>
                        <mat-select formControlName="licenseType">
                          <mat-option *ngFor="let licenseType of validAvailableLicenses"
                            [value]="licenseType.licenseTypeId">
                            {{'create_product.' + getTranslatedName(licenseType.name) | translate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container">
                <div class="row">

                  <div class="col md-4">
                    <label for="addCategory">{{'create_product.addCategory' |
                      translate}}</label>
                    <div class="col mb-2">
                      <mat-form-field appearance="fill" class="dropdown">
                        <mat-label>{{ 'create_product.addCategory' | translate }}</mat-label>
                        <mat-select formControlName="selectedCategories" multiple>
                          <mat-option *ngFor="let category of CategoriesData"
                            [value]="category.additionalData['en'].categoryName.toLowerCase()">
                            {{ category.additionalData[currentLang].categoryName.toLowerCase() }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="col md-4">
                    <label for="availability">{{'create_product.availabilty_crp' | translate}}</label>
                    <input type="number" min="1" formControlName="availability" name="availability" id="availability"
                      class="inputSize customInput form-control"
                      placeholder="{{'create_product.availabilty_crp' | translate}}*">
                  </div>

                  <div class="col md-4">
                    <label for="ageRange">{{'create_product.agre_range_crp' | translate}}</label>
                    <input type="number" min="1" max="12" formControlName="ageRange" name="ageRange" id="ageRange"
                      class="inputSize end customInput form-control"
                      placeholder="{{'create_product.agre_range_crp' | translate}}" (input)="LimitValue($event)">
                  </div>
                </div>
              </div>

              <div class="container">
                <div class="row">
                  <div class="col md-6">
                    <label for="interactiveCode">{{'create_product.interactive_product_crp' | translate}}</label>
                    <input type="text" formControlName="interactiveCode" name="interactiveCode" id="interactiveCode"
                      class="inputSize customInput"
                      placeholder="{{'create_product.interactive_product_crp' | translate}}*">
                  </div>
                  <div class="col md-6">
                    <label for="productCode">{{'create_product.product_code_crp' | translate}}</label>
                    <input type="text" formControlName="productCode" name="productCode" id="productCode"
                      class="inputSize end customInput form-control"
                      placeholder="{{'create_product.product_code_crp' | translate}}*">
                  </div>
                </div>
              </div>
              <div class="container">
                <div class="filesContainer">
                  <div class="exam">
                    <label for="contentFileName">{{'create_product.interactive_content' | translate}}</label>
                    <input type="text" id="contentFileName" class="inputSize end customInput"
                      placeholder="{{'create_product.interactive_content' | translate}}" disabled>
                  </div>

                  <div id="file-body">
                    <label for="content">
                      <div class="mat-raised-button mat-button-base lg-i3book-button exam">
                        {{'create_product.examine_crp' | translate}}
                      </div>
                    </label>
                    <input type="file" formControlName="content" (change)="onFileChange($event, 'content')"
                      name="content" id="content">
                    <!-- <input type="file" formControlName="content" (change)="onFileChange($event, 'content')" name="content" id="content" class="inputSize customInput" placeholder="Contenido interactivo"> -->
                  </div>




                  <div class="exam">
                    <label for="coverImageFileName">{{'create_product.cover_crp' | translate}}</label>
                    <input type="text" id="coverImageFileName" class="inputSize end customInput"
                      placeholder="{{'create_product.cover_crp' | translate}}" disabled>
                  </div>

                  <div id="file-body">
                    <label for="coverImage">
                      <div class="mat-raised-button mat-button-base lg-i3book-button exam">
                        {{'create_product.examine_crp' | translate}}
                      </div>
                    </label>
                    <input type="file" formControlName="coverImage" (change)="onFileChange($event, 'coverImage')"
                      name="coverImage" id="coverImage">
                    <!-- <input type="file" formControlName="coverImage" (change)="onFileChange($event, 'coverImage')" name="coverImage" id="coverImage" class="inputSize customInput" placeholder="Portada"> -->
                  </div>

                </div>
                <div class="col md-12">
                  <!-- Mensaje de error -->
                  <label *ngIf="fileError" class="error-message">
                    {{ fileError }}
                  </label>
                </div>
                <div class="col md-12">
                  <label for="bookLink">{{'create_product.book_link' | translate}}</label>
                  <input type="text" formControlName="bookLink" name="bookLink" id="bookLink"
                    class="inputSize end customInput form-control"
                    placeholder="{{'create_product.book_link' | translate}}*">
                </div>

              </div>

              <div class="container">
                <div class="row">
                  <div class="col-12">
                    <label for="editorialBook" style="padding-left: 0">{{'create_product.book_editorial' |
                      translate}}</label>
                    <div class="col mb-2">
                      <mat-form-field appearance="fill" class="dropdown w-100">
                        <mat-label>{{'create_product.book_editorial' | translate}}</mat-label>
                        <mat-select formControlName="editorialBook">
                          <mat-option *ngFor="let editorialBook of validateEditorials"
                            [value]="editorialBook.editorialBookId">
                            {{editorialBook.name | translate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Separador -->
    <div class="row">
      <div class="col">
        <div class="lineSeparator"></div>
      </div>
    </div>
    <hr>
    <!-- Texto multi-idioma -->
    <div class="row d-flex justify-content-evenly">
      <div class="col-5">
        <p class="subtitlesAddBook d-flex justify-content-center">{{'create_product.multi_language_text_crp' |
          translate}}</p>
        <p class="smalltitlesAddbok d-flex justify-content-center">{{'create_product.add_book_languages_crp' |
          translate}}</p>
      </div>
      <div class="col-7">
        <div class="accordion accordion-item">
          <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
            <a class="accordion-button collapsed addBookButton" type="button" data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
              aria-controls="panelsStayOpen-collapseTwo"><i class="bi bi-plus-circle-fill sizeIconAdd"></i><b
                class="addBookButton"> {{'create_product.add_crp' | translate}}</b></a>
          </h2>
          <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingTwo">
            <div class="accordion-body">
              <div [formGroup]="languageForm">
                <div class="container">
                  <div class="row">
                    <div class="col">
                      <label class="form-label text-start" for="language"
                        style="padding-left: 0">{{'create_product.language_crp' |
                        translate}}</label>
                      <mat-form-field appearance="fill" class="dropdown">
                        <mat-label>{{'create_product.language_crp' | translate}}</mat-label>
                        <mat-select formControlName="language">
                          <mat-option *ngFor="let lang of validAvailableLanguages" [value]="lang">
                            {{lang.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <label class="form-label text-start" for="title">{{'create_product.title_crp' |
                        translate}}</label>
                      <input type="text" formControlName="title" type="text" name="title" id="title"
                        class="inputSize customInput" placeholder="{{'create_product.title_crp' | translate}}">
                      <label class="form-label text-start" for="short_desc">{{'create_product.short_description_crp' |
                        translate}}</label>
                      <input type="text" formControlName="shortDescription" type="text" name="short_desc"
                        id="short_desc" class="inputSize customInput"
                        placeholder="{{'create_product.short_description_crp' | translate}}">
                    </div>
                    <div class="col">
                      <label class="form-label text-start" for="resume">{{'create_product.summary_crp' |
                        translate}}</label>
                      <textarea formControlName="resume" name="resume" id="resume" cols="47" rows="10"
                        class="form-control customInput textareaSize"
                        placeholder="{{'create_product.summary_crp' | translate}}"></textarea>
                    </div>
                  </div>
                </div>

                <div class="row"> <!-- Buttons -->
                  <div class="col">
                    <button *ngIf="isUpdatingLanguage" (click)="editLanguageData()"
                      class="mat-raised-button mat-button-base lg-i3book-button m-2">
                      {{'update_product.update_up' | translate}}
                    </button>
                    <button *ngIf="isUpdatingLanguage" (click)="discardLanguageEdit()"
                      class="mat-raised-button mat-button-base lg-i3book-button-white m-2">
                      {{'create_product.discard_crp' | translate}}
                    </button>
                    <button *ngIf="!isUpdatingLanguage" (click)="addLanguageData()"
                      class="mat-raised-button mat-button-base lg-i3book-button m-2">
                      {{'create_product.save_crp' | translate}}
                    </button>
                  </div>
                </div> <!-- Buttons -->

              </div>
              <div class="row">
                <table mat-table matSort #languageTableSort="matSort" #languageTable [dataSource]="languageDataSource"
                  class="table mb-5 w-100 text-start">
                  <!-- index Column -->
                  <ng-container matColumnDef="index">
                    <th mat-header-cell *matHeaderCellDef class="sizeThead">#</th>
                    <td mat-cell *matCellDef="let element">{{element.index}}</td>
                  </ng-container>

                  <!-- language Column -->
                  <ng-container matColumnDef="language">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by language"
                      class="sizeThead">{{'create_product.language_crp' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.language.name}}</td>
                  </ng-container>

                  <!-- title Column -->
                  <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by title"
                      class="sizeThead">{{'create_product.title_crp' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.title}}</td>
                  </ng-container>

                  <!-- title Column -->
                  <!-- <ng-container matColumnDef="weight">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by weight" class="sizeThead">{{'create_product.book_weight' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">{{element.weight}}</td>
                                  </ng-container> -->

                  <!-- title Column -->
                  <ng-container matColumnDef="shortDesc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by shortDesc"
                      class="sizeThead">{{'create_product.short_description_crp' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.shortDescription}}</td>
                  </ng-container>

                  <!-- actions Column -->
                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="sizeThead">{{'create_product.actions_crp' |
                      translate}}</th>
                    <td mat-cell *matCellDef="let element">
                      <button class="btn" (click)="setLanguageFormToEdit(element.index)"><i
                          class="bi bi-pencil-fill colorIconAction"></i></button>
                      <button class="btn" (click)="removeLanguageData(element.index)" *ngIf="!isUpdatingLanguage"><i
                          class="bi bi-trash-fill colorIconAction"></i></button>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="languageDisplayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: languageDisplayedColumns;"></tr>

                  <!-- Row shown when there is no matching data that will be provided to the wrapper table. -->
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell no-data-centered" colspan="5">{{'create_product.empty_crp' | translate}}</td>
                  </tr>
                </table>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Separador -->
    <div class="row">
      <div class="col">
        <div class="lineSeparator"></div>
      </div>
    </div>
    <hr>
    <!-- Valor -->
    <div class="row d-flex justify-content-evenly">
      <div class="col-5">
        <p class="subtitlesAddBook d-flex justify-content-center">{{'create_product.price_crp' | translate}}</p>
        <p class="smalltitlesAddbok d-flex justify-content-center">{{'create_product.add_price_book_crp' | translate}}
        </p>
      </div>
      <div class="col-7">
        <div class="accordion accordion-item">
          <h2 class="accordion-header" id="panelsStayOpen-headingThree">
            <a class="accordion-button collapsed addBookButton" type="button" data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
              aria-controls="panelsStayOpen-collapseThree"><i class="bi bi-plus-circle-fill sizeIconAdd"></i><b
                class="addBookButton">{{'create_product.add_crp' | translate}}</b></a>
          </h2>
          <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingThree">
            <div class="accordion-body">
              <div [formGroup]="priceForm">
                <div class="container">
                  <div class="row">
                    <div class="col md-6">
                      <label for="" style="padding-left: 0">{{'create_product.country_crp' | translate}}</label>
                      <div class="col mb-2">
                        <mat-form-field appearance="fill" class="dropdown">
                          <mat-label>{{'create_product.country_crp' | translate}}</mat-label>
                          <mat-select formControlName="country">
                            <mat-option *ngFor="let country of validAvailableCountries" [value]="country"
                              [disabled]="isCountryDisabled(country)">
                              {{country.countryInfo.name}} - {{country.currency.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col md-6">
                      <!-- Valor sin impuestos -->
                      <label for="">{{'create_product.unit_price_before_tax_crp' | translate}}</label>
                      <input type="number" step=".00" formControlName="amount" name="amount" id="amount"
                        class="inputSize end customInput" (input)="getTotalPrice(inputAmount.value)" #inputAmount
                        placeholder="{{'create_product.unit_price_before_tax_crp' | translate}}">
                    </div>
                  </div>
                  <div class="row">
                    <!-- Impuesto (%) -->
                    <div class="col md-6">
                      <label for="">{{'create_product.tax_percentage_crp' | translate}}</label>
                      <input type="number" step=".0000" min="0" max="100" formControlName="taxPercValue"
                        name="taxPercValue" id="taxPercValue" class="inputSize customInput"
                        placeholder="{{'create_product.tax_percentage_crp' | translate}}" #taxPercValue
                        (input)="setCurrentTax($event)">
                    </div>
                    <!-- Impuestos -->
                    <div class="col">
                      <label for="">{{'create_product.tax_crp' | translate}} {{currentTaxesPercentage}}%</label>
                      <input type="number" step=".0000" formControlName="taxes" name="taxes" id="taxes" #taxes
                        class="inputSize end customInput" placeholder="{{'create_product.tax_crp' | translate}}">
                    </div>
                  </div>
                  <div class="row">
                    <!-- Total -->
                    <div class="col md-6">
                      <label for="">{{'create_product.total_crp' | translate}}</label>
                      <input type="number" step=".00" formControlName="totalValue" name="totalValue" id="totalValue"
                        #totalValue class="inputSize end customInput form-control"
                        placeholder="{{'create_product.total_crp' | translate}}">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <button *ngIf="isUpdatingPrice" mat-raised-button (click)="editPriceData()"
                      name="prices_editPricesButton" class="mat-raised-button mat-button-base lg-i3book-button m-2">
                      {{'update_product.update_up' | translate}}
                    </button>
                    <button *ngIf="isUpdatingPrice" mat-raised-button (click)="discardPriceEdit()"
                      name="prices_discardChangesButton"
                      class="mat-raised-button mat-button-base lg-i3book-button-white m-2">
                      {{'create_product.discard_crp' | translate}}
                    </button>
                    <button *ngIf="!isUpdatingPrice" mat-raised-button (click)="addPriceData()"
                      name="prices_addChangesButton" class="mat-raised-button mat-button-base lg-i3book-button m-2">
                      {{'create_product.save_crp' | translate}}
                    </button>
                  </div>
                </div>
              </div>
              <div class="row">
                <table mat-table matSort #priceTableSort="matSort" #priceTable [dataSource]="priceDataSource"
                  name="price_table" class="table mb-4 w-100">
                  <!-- index Column -->
                  <ng-container matColumnDef="index">
                    <th mat-header-cell *matHeaderCellDef class="sizeThead">#</th>
                    <td mat-cell *matCellDef="let element">{{element.index}}</td>
                  </ng-container>

                  <!-- country Column -->
                  <ng-container matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by country"
                      class="sizeThead">{{'create_product.country_crp' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.country.countryInfo.name}}</td>
                  </ng-container>

                  <!-- amount Column -->
                  <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by amount"
                      class="sizeThead">{{'create_product.price_crp' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.country.currency.symbol}} {{element.amount}}</td>
                  </ng-container>

                  <!-- taxPercentage -->
                  <ng-container matColumnDef="taxPercentage">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by taxes"
                      class="sizeThead">{{'create_product.tax_percentage_crp' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.taxPercentage}}</td>
                  </ng-container>

                  <!-- taxes Column -->
                  <ng-container matColumnDef="taxes">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by taxes"
                      class="sizeThead">{{'create_product.tax_crp' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.country.currency.symbol}} {{element.taxes}}</td>
                  </ng-container>

                  <!-- weight Column -->
                  <!-- <ng-container matColumnDef="weight">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by amount" class="sizeThead">{{'create_product.price_crp' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">{{element.weight}}</td>
                                  </ng-container> -->

                  <!-- amount Column -->
                  <ng-container matColumnDef="totalValue">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by totalCost"
                      class="sizeThead">{{'create_product.total_amount_crp' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.country.currency.symbol}}
                      {{getTotalCost(element)}}</td>
                  </ng-container>

                  <!-- actions Column -->
                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="sizeThead">{{'create_product.actions_crp' |
                      translate}}</th>
                    <td mat-cell *matCellDef="let element">
                      <button class="btn" (click)="setPriceFormToEdit(element.index)"><i
                          class="bi bi-pencil-fill colorIconAction"></i></button>
                      <button class="btn" (click)="removePriceData(element.index)"><i
                          class="bi bi-trash-fill colorIconAction"></i></button>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="priceDisplayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: priceDisplayedColumns;"></tr>
                  <!-- Row shown when there is no matching data that will be provided to the wrapper table. -->
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell no-data-centered" colspan="6">No data</td>
                  </tr>
                </table>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Separador -->
    <div class="row">
      <div class="col">
        <div class="lineSeparator"></div>
      </div>
    </div>

    <hr>
    <div class="row d-flex justify-content-evenly">
      <div class="col-5">
        <p class="subtitlesAddBook d-flex justify-content-center">{{'Sizes.title' | translate}}</p>
        <small class="smalltitlesAddbok d-flex justify-content-center">{{'Sizes.description' | translate}}</small>
      </div>
      <div class="col-7">
        <div class="accordion accordion-item">
          <h2 class="accordion-header" id="sizes-headingOne">
            <!-- Pesos y tamaños del libro -->
            <a class="accordion-button collapsed addBookButton" type="button" data-bs-toggle="collapse"
              data-bs-target="#sizes-collapseOne" aria-expanded="false" aria-controls="sizes-collapseOne"><i
                class="bi bi-plus-circle-fill sizeIconAdd"></i><b class="addBookButton"> {{'create_product.add_crp' |
                translate}}</b></a>
          </h2>
          <div id="sizes-collapseOne" class="accordion-collapse collapse" aria-labelledby="sizes-headingOne">
            <div class="accordion-body">
              <!-- Form of shipping fields -->
              <div [formGroup]="shippingForm">
                <div class="container">
                  <div class="row">
                    <div class="col md-6">
                      <label for="height" style="padding-left: 0">{{'Sizes.height' | translate}}</label>
                      <input type="number" min="1" formControlName="height" name="height" id="height"
                        class="inputSize customInput form-control" placeholder="{{'Sizes.height' | translate}}">
                    </div>
                    <div class="col md-6">
                      <label for="width" style="padding-left: 0">{{'Sizes.width' | translate}}</label>
                      <input type="number" formControlName="width" name="width" id="width"
                        class="inputSize customInput form-control" placeholder="{{'Sizes.width' | translate}}">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col md-6">
                      <label for="unit_weight" style="padding-left: 0">{{'Sizes.unit_weight' | translate}}</label>
                      <div class="col mb-2">
                        <mat-form-field appearance="fill" class="dropdown">
                          <mat-label>{{'Sizes.unit_weight' | translate}}</mat-label>
                          <mat-select formControlName="unit_weight">
                            <mat-option value="Kg">
                              kg
                            </mat-option>
                            <mat-option value="Lb">
                              lb
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col md-6">
                      <label for="weight" style="padding-left: 0">{{'Sizes.weight' | translate}}</label>
                      <input type="text" formControlName="weight" name="weight" id="weight"
                        class="inputSize customInput form-control" placeholder="{{'Sizes.weight' | translate}}">

                    </div>
                  </div>
                  <div class="row">
                    <div class="col md-6">
                      <label for="unit_length" style="padding-left: 0">{{'Sizes.unit_length' | translate}}</label>
                      <div class="col mb-2">
                        <mat-form-field appearance="fill" class="dropdown">
                          <mat-label>{{'Sizes.unit_length' | translate}}</mat-label>
                          <mat-select formControlName="unit_sizes">
                            <mat-option value="Cm">
                              cm
                            </mat-option>
                            <mat-option value="In">
                              in
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col md-6">
                      <label for="length" style="padding-left: 0">{{'Sizes.Depth' | translate}}</label>
                      <input type="text" formControlName="length" name="length" id="length"
                        class="inputSize customInput form-control" placeholder="{{'Sizes.Depth' | translate}}">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col md-6">
                      <label for="" style="padding-left: 0">{{'create_product.country_crp' | translate}}</label>
                      <div class="col mb-2">
                        <mat-form-field appearance="fill" class="dropdown">
                          <mat-label>{{'create_product.country_crp' | translate}}</mat-label>
                          <mat-select formControlName="country">
                            <mat-option *ngFor="let country of validAvailableCountries" [value]="country">
                              {{country.countryInfo.name}} - {{country.currency.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col md-6">
                      <label for="price" style="padding-left: 0">{{'Sizes.price' | translate}}</label>
                      <input type="number" formControlName="price" name="price" id="price"
                        class="inputSize customInput form-control" placeholder="{{'Sizes.price' | translate}}">
                    </div>
                  </div>
                </div>
                <div class="row"> <!-- Buttons -->
                  <div class="col">
                    <button *ngIf="isUpdatingShippingCosts" (click)="editShippingData()"
                      class="mat-raised-button mat-button-base lg-i3book-button m-2">
                      {{'update_product.update_up' | translate}}
                    </button>
                    <button *ngIf="isUpdatingShippingCosts" (click)="discardLanguageEdit()"
                      class="mat-raised-button mat-button-base lg-i3book-button-white m-2">
                      {{'create_product.discard_crp' | translate}}
                    </button>
                    <button *ngIf="!isUpdatingShippingCosts" (click)="addShippingCostsData()"
                      class="mat-raised-button mat-button-base lg-i3book-button m-2">
                      {{'create_product.save_crp' | translate}}
                    </button>
                  </div>
                </div> <!-- Buttons -->
              </div>
              <div class="row"> <!--Tabla de pesos -->
                <table mat-table matSort #shippingCostsTableSort="matSort" #shippingCostsTable
                  [dataSource]="shippingTableDataSource" class="table mb-4 w-100 text-start">
                  <!-- index Column -->
                  <ng-container matColumnDef="index">
                    <th mat-header-cell *matHeaderCellDef class="sizeThead">#</th>
                    <td mat-cell *matCellDef="let element">{{element.index}}</td>
                  </ng-container>

                  <!-- Country Column -->
                  <ng-container matColumnDef="weightTable_country">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by country"
                      class="sizeThead">{{'create_product.country_crp' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.country}}</td>
                  </ng-container>

                  <!-- Weight Column -->
                  <ng-container matColumnDef="weightTable_weight">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by title"
                      class="sizeThead">{{'Sizes.weight' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.weight}}</td>
                  </ng-container>

                  <!-- Unit Weight Column -->
                  <ng-container matColumnDef="weightTable_unitWeight">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by weight"
                      class="sizeThead">{{'Sizes.unit_weight' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.unit_weight}}</td>
                  </ng-container>

                  <!-- Length Column -->
                  <ng-container matColumnDef="weightTable_length">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by shortDesc"
                      class="sizeThead">{{'Sizes.Depth' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.length}}</td>
                  </ng-container>

                  <!-- Height Column -->
                  <ng-container matColumnDef="weightTable_height">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by shortDesc"
                      class="sizeThead">{{'Sizes.height' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.height}}</td>
                  </ng-container>

                  <!-- Width Column -->
                  <ng-container matColumnDef="weightTable_width">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by shortDesc"
                      class="sizeThead">{{'Sizes.width' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.width}}</td>
                  </ng-container>

                  <!-- Width Column -->
                  <ng-container matColumnDef="weightTable_unitLength">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by shortDesc"
                      class="sizeThead">{{'Sizes.unit_length' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.unit_sizes}}</td>
                  </ng-container>

                  <!-- Price Column -->
                  <ng-container matColumnDef="weightTable_price">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by shortDesc"
                      class="sizeThead">{{'Sizes.price' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.price}}</td>
                  </ng-container>


                  <!-- actions Column -->
                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="sizeThead">{{'create_product.actions_crp' |
                      translate}}</th>
                    <td mat-cell *matCellDef="let element">
                      <!-- <button class="btn" (click)="setShippingFormToEdit(element.index)"><i
                          class="bi bi-pencil-fill colorIconAction"></i></button> -->
                      <button class="btn" (click)="removeShippingData(element.index)"
                        *ngIf="!isUpdatingShippingCosts"><i class="bi bi-trash-fill colorIconAction"></i></button>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="shippingTableDisplayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: shippingTableDisplayedColumns;"></tr>

                  <!-- Row shown when there is no matching data that will be provided to the wrapper table. -->
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell no-data-centered" colspan="5">{{'create_product.empty_crp' | translate}}</td>
                  </tr>
                </table>
              </div>


            </div>
          </div>
        </div>
      </div>



    </div>
    <!-- Botones -->
    <br><br>
    <div class="row p-3">
      <div class="col text-center">
        <input class="sendAddBook" type="button" (click)="onSubmit()" value="{{'create_product.send' | translate}}">
        <button class="closeAddBook" (click)="close()">{{'update_product.close_up' | translate}}</button>
      </div>
    </div>
  </form>
</div>