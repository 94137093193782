import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductType } from '../../models/product-type.models';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Language, LanguageTableContent, PriceTableContent, Product, Shipping_costsEdit as ShippingCostsTableContent, SizeTableContent } from '../../models/edit-book.models';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { LicenseTypeService } from '../../services/license-type.service';
import { LanguageService } from 'src/app/languages/services/language.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { EditorialService } from 'src/app/editorials/services/editorial.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { CountryInfoWithCurrency } from '../../models/country-info-with-currency.model';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { Category } from '../../models/category-product.models';
import { CategoryProductService } from '../../services/category-product.service';
import { TranslateService } from '@ngx-translate/core';
import { EditorialsBooksService } from '../../services/editorials-books.service';


@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})
export class EditProductComponent implements OnInit {

  @ViewChild('taxPercValue') taxPercValue: any;
  @ViewChild('inputAmount') inputAmount: any;
  @ViewChild('totalValue') totalValue: any;

  readonly editProductObserver = {
    next: (data: CommonResponse<any>) => this.editProductNext(data),
    error: (errorStatusCode: number) => this.editProductError(errorStatusCode),
    complete: () => this.editProductComplete()

  }

  bookName: string;

  //Default options for form selects
  validAvailableLanguages: Language[];
  validAvailableCountries: CountryInfoWithCurrency[];
  validAvailableLicenses: any;
  productId: number;
  CategoriesData: Category[];
  productType: ProductType[] = ProductType.data;
  dataProduct: Product;
  validDataProduct: any;
  validAvailableCountriesVar: any;
  taxesCountries: { [key: string]: number } = {};
  currentTaxesPercentage: number = 0;
  currentTaxesAmount: number = 0;
  currentLang: string;



  //Input book data
  productForm: FormGroup;
  languageForm: FormGroup;
  shippingForm: FormGroup;
  shippingFormTable: FormGroup;
  priceForm: FormGroup;
  selectedElement: any;
  selectedLanguage: any;
  selectedPrice: any;
  selectedElementPrice: any;
  selectedElementSize: any;

  formSubmitted: boolean = false;
  validateEditorials: any;
  fileError: string | null = null; // Variable para el mensaje de error

  constructor(
    private _EditorialsBooksService: EditorialsBooksService,
    private _formBuilder: FormBuilder,
    private _licenseTypeService: LicenseTypeService,
    private _languageService: LanguageService,
    private _editorialService: EditorialService,
    private _snackbarService: SnackbarService,
    private _translateService: TranslateService,
    private _activatedRoute: ActivatedRoute,
    private _pageLoadingService: PageLoadingService,
    private _category_productService: CategoryProductService,
    private _router: Router
  ) {
    this.currentLang = this._translateService.currentLang;

    this._translateService.onLangChange.subscribe(() => {
      this.currentLang = this._translateService.currentLang;
    });

    this.productId = this._activatedRoute.snapshot.params['id'];
    this.productForm = this._formBuilder.group({
      productCode: [''],
      interactiveCode: [''],
      availability: new FormControl(['']),
      isbn: (['']),
      licenseType: [''],
      bookLink: [['']],
      selectedCategories: [[]],
      isPreview: [false],
      content: [''],
      contentSource: [''],
      coverImage: [''],
      coverImageSource: [''],
      type: [''],
      uniqueMagazineCode: [''],
      productId: [''],
      unit_weight: [''],
      weight: [''],
      unit_sizes: [''],
      length: [''],
      height: [''],
      width: [''],
      editorialBook: [[]],

    });

    this.priceForm = this._formBuilder.group({
      'position': new FormControl(''),
      'amount': ['', [Validators.required, this.numericValidator]],
      'taxes': ['', [Validators.required, this.numericValidator]],
      'country': ['', [Validators.required]],
      'taxPercValue': new FormControl(''),
      'totalValue': new FormControl(''),
    });

    this.languageForm = this._formBuilder.group({
      'index': new FormControl(''),
      'language': new FormControl(''),
      'title': new FormControl(''),
      'shortDescription': new FormControl(''),
      'resume': new FormControl('')
    });

    this.shippingForm = this._formBuilder.group({
      'index': new FormControl(''),
      'country': new FormControl(''),
      'weight': new FormControl(''),
      'unit_weight': new FormControl(''),
      'length': new FormControl(''),
      'height': new FormControl(''),
      'width': new FormControl(''),
      'unit_sizes': new FormControl(''),
      'price': new FormControl('')
    });

    this.shippingFormTable = this._formBuilder.group({
      'index': new FormControl(''),
      'weightTable_country': new FormControl(''),
      'weightTable_weight': new FormControl(''),
      'weightTable_unitWeight': new FormControl(''),
      'weightTable_length': new FormControl(''),
      'weightTable_height': new FormControl(''),
      'weightTable_width': new FormControl(''),
      'weightTable_unitLength': new FormControl(''),
      'weightTable_price': new FormControl('')
    });

    this.initializeAvailableLicenses();
    this.initializeAvailableLanguages();
    this.initializeAvailableCountries11();

    this._editorialService.editProduct(this.productId)
      .subscribe(this.editProductObserver);
  }

  numericValidator = (control: FormControl) => {
    const value = control.value;
    if (isNaN(value) || value < 0) {
      return { numeric: true };
    }
    return null;
  };

  initializeAvailableLanguages() {
    this._languageService.getAllLanguages().subscribe(data => {
      if (ResponseHelper.responseDontHaveErrors(data)) {
        this.validAvailableLanguages = (data as CommonResponse<any>).data;
      }
      else {

      }
    });
  }

  initializeAvailableLicenses() {
    this._licenseTypeService.getAllServices().subscribe(data => {
      if (ResponseHelper.responseDontHaveErrors(data)) {
        this.validAvailableLicenses = (data as CommonResponse<any>).data;
      }
      else {

      }
    });
  }

  initializeAvailableCountries11() {
    this._editorialService.getEditorialCountries().subscribe(data => {
      if (ResponseHelper.responseDontHaveErrors(data)) {
        this.validAvailableCountries = (data as CommonResponse<any>).data;
      }
      else {

      }
    });
  }

  initializeAvailableCountries() {
    this._editorialService.getEditorialCountries().subscribe(data => {
      if (ResponseHelper.responseDontHaveErrors(data)) {
        this.validAvailableCountriesVar = data.data;
        for (let i = 0; i < this.validAvailableCountriesVar.length; i++) {
          const countryName = this.validAvailableCountriesVar[i].countryInfo.name;
          const taxes = this.validAvailableCountriesVar[i].countryInfo.taxes;
          this.taxesCountries[countryName] = taxes;
        }
      }
      else {

      }
    });
  }

  /**
   * @deprecated This function will be remove due client request taxes must be by product instead by country
   * @param country 
   */
  changeCountryTax(country: any) {
    this.currentTaxesPercentage = country.countryInfo.taxes;
    this.priceForm?.get('amount')?.setValue('');
    this.priceForm?.get('taxes')?.setValue('');
  }
  setCurrentTax(taxes: any) {
    this.currentTaxesPercentage = this.taxPercValue.nativeElement.value;
    this.getTotalPrice(this.inputAmount.nativeElement.value)

  }

  getTotalPrice(amount: any) {
    this.currentTaxesAmount = parseFloat(amount) * this.currentTaxesPercentage / 100;
    let totalPrice = parseFloat(amount) + (parseFloat(amount) * this.currentTaxesPercentage / 100);
    let inputAmount = document.getElementById('taxes');
    this.priceForm?.get('taxes')?.setValue(this.currentTaxesAmount);
    this.priceForm?.get('totalValue')?.setValue(totalPrice);
  }

  languageDisplayedColumns: string[] = ['position', 'language', 'title', 'shortDesc', 'actions'];
  languageDataSource = new MatTableDataSource<LanguageTableContent>([]);
  isUpdatingLanguage: boolean = false;
  previousLanguage: any;

  priceDisplayedColumns: string[] = ['index', 'country', 'amount', 'taxPercentage', 'taxes', 'totalValue', 'actions'];
  priceDataSource = new MatTableDataSource<PriceTableContent>([]);
  isUpdatingPrice: boolean = false;
  previousPriceCountry: any;

  shippingTableDisplayedColumns: string[] = ['index', 'weightTable_country', 'weightTable_weight', 'weightTable_unitWeight', 'weightTable_length', 'weightTable_height', 'weightTable_width', 'weightTable_unitLength', 'weightTable_price', 'actions'];
  shippingTableDataSource = new MatTableDataSource<ShippingCostsTableContent>([]);
  isUpdatingShippingCosts: boolean = false;
  previousShippingCosts: any;


  @ViewChild('languageTableSort')
  languageTableSort: MatSort;

  @ViewChild('languageTable')
  languageTable: MatTable<LanguageTableContent>;

  @ViewChild('priceTableSort')
  priceTableSort: MatSort;

  @ViewChild('priceTable')
  priceTable: MatTable<PriceTableContent>;

  @ViewChild('shippingCostsTableSort')
  shippingCostsTableSort: MatSort;

  @ViewChild('shippingCostsTable')
  shippingCostsTable: MatTable<ShippingCostsTableContent>;

  sortingLanguageAccessor = (item: { [key: string]: any }, property: string) => {
    switch (property) {
      case 'language': return (item as LanguageTableContent).language.name;
      default: return item[property];
    }
  };

  sortinPriceAccessor = (item: { [key: string]: any }, property: string) => {
    switch (property) {
      case 'country': return (item as PriceTableContent).country.countryInfo.name;
      default: return item[property];
    }
  };

  ngOnInit(): void {
    this.getAllEditorials();
    this.getAllCategories();
    this.languageDataSource.sortingDataAccessor = this.sortingLanguageAccessor;
    this.priceDataSource.sortingDataAccessor = this.sortinPriceAccessor;
  }

  getAllEditorials() {
    //console.log("funcion para hacer la peticion de las editoriales");
    this._EditorialsBooksService.getAllEditorials().subscribe(data => {
      if (ResponseHelper.responseDontHaveErrors(data)) {
        this.validateEditorials = data.data;
      }
      else {

      }
      //console.log('datos del servicio', this.validateEditorials);
    });
  }

  ngAfterViewInit() {
    this.languageTableSort.disableClear = true;
    this.priceTableSort.disableClear = true;
  }

  onFileChange(event: any, formControlName: string) {

    if (event.target?.files?.length > 0) {
      const file = event.target?.files[0];
      const maxSize = 50 * 1024 * 1024;
      const data: { [key: string]: File } = {};
      data[`${formControlName}Source`] = file;
      this.productForm.patchValue(data);

      if (file.size > maxSize) {
        //console.log('El archivo supera el tamaño máximo permitido de 50MB');
        this.fileError = this._translateService.instant('create_product.msg_error');
        file.value = '';

        return;
      }

      // Si el archivo es válido, limpia el mensaje de error
      this.fileError = null;
      //console.log('Archivo válido:', file.name);

      const fileNameLabel = document.getElementById(`${formControlName}FileName`) as HTMLInputElement;
      fileNameLabel?.setAttribute('value', event.target?.files[0].name);
    }
  }

  get f() {
    return this.productForm.controls;
  }

  hasCategorySelection(): boolean {
    return this.productForm.value.selectedCategories.length > 0;
  }


  getAllCategories() {
    this._category_productService.getCategory()
      .subscribe({
        next: (response) => {
          this.CategoriesData = (response.data as any[]).filter(category => category.isDelete === 0);
          this.CategoriesData.forEach((category) => {
            let temp = category.additionalData;
            category.additionalData = JSON.parse(temp);
          });
        },
        error: (error) => {
          console.error('Error al hacer la petición:', error);
        }
      });
  }

  editProductNext(data: CommonResponse<any>) {

    this.validDataProduct = data.data;
    //console.log('datos a setear:', this.validDataProduct.product.book.editorialBookId);


    this.bookName = this.validDataProduct.product.product_general_info[0].title

    this.dataProduct = this.validDataProduct;
    if (this.dataProduct.product.field_data.length > 0) {

      const categoriesString = this.dataProduct.product.field_data[0].data;
      const categoriesArray = JSON.parse(categoriesString);
      this.productForm.controls['selectedCategories'].setValue(categoriesArray);
      this.productForm.controls['editorialBook'].setValue(this.validDataProduct.product.book.editorialBookId);


    }

    this.productForm.controls['interactiveCode'].setValue(this.dataProduct.product.interactiveCode);
    this.productForm.controls['availability'].setValue(this.dataProduct.availability);
    this.productForm.controls['isbn'].setValue(this.dataProduct.product.book.isbn);
    this.productForm.controls['bookLink'].setValue(this.dataProduct.product.url);
    this.productForm.controls['licenseType'].setValue(this.dataProduct.licenseType);
    this.productForm.controls['productId'].setValue(this.dataProduct.product.productId);

    if (this.dataProduct.product.product_general_info.length > 0) {

      this.languageDataSource.data = this.dataProduct.product.product_general_info;
      this.languageDataSource.data = this.dataProduct.product.product_general_info.map((item, index) => ({ ...item, index: index + 1 }));
    }

    if (this.dataProduct.prices.length > 0) {

      this.priceDataSource.data = this.dataProduct.prices.map((item, index) => {
        const country = this.validAvailableCountries.find(country => country.countryInfo.countryCode == item.country.countryCode);

        const priceFormDbAsPriceElement: PriceTableContent = {
          index: index,
          amount: item.amount,
          taxes: item.taxes,
          country: country,
          totalValue: item.totalValue,
          taxPercentage: item.taxPercentage,
        };

        return priceFormDbAsPriceElement;
      });
    }

    if (this.dataProduct.product.shipping_cost.length > 0) {

      this.shippingTableDataSource.data = this.dataProduct.product.shipping_cost.map((item, index) => {
        const country = this.validAvailableCountries.find(country => country.countryInfo.countryCode == item.countryCode);

        const shippingFormDbAsPriceElement: ShippingCostsTableContent = {
          index: index,
          height: item.height,
          length: item.length,
          unit_sizes: item.unit_sizes,
          unit_weight: item.unit_weight,
          weight: item.weight,
          width: item.width,
          country: country,
          price: item.price
        };

        return shippingFormDbAsPriceElement;
      });
    }

    if (this.dataProduct.product.magazine != null) {
      this.productForm.controls['uniqueMagazineCode'].setValue(this.dataProduct.product.magazine.uniqueMagazineCode);
      this.productForm.controls['productCode'].setValue(this.dataProduct.product.magazine.issn);
      this.productForm.controls['type'].setValue("Magazine")
    }
    else {
      this.productForm.controls['uniqueMagazineCode'].setValue(null);
      this.productForm.controls['productCode'].setValue(this.dataProduct.product.book.isbn);
      this.productForm.controls['type'].setValue("Book")
    }

    this._pageLoadingService.showLoadingGif();
    this._pageLoadingService.hideLoadingGif();
  }

  editProductError(errorStatusCode: number) {

    if (errorStatusCode == 404) {
      this._snackbarService.openStandardSnackBar("editProductError404");
    }
    else {
      this._snackbarService.openStandardSnackBar("editProductError");
    }
  }

  editProductComplete() {
    //aquí va el gif
  }

  addLanguageData() {

    const isValidlanguageForm = this.validateFormData(this.languageForm);;

    if (!isValidlanguageForm)
      return;

    if (!this.isLanguageFilled(this.languageForm?.get('language')?.value.languageCode)) {
      const addLanguageFunction = () => {
        const newLanguageData: LanguageTableContent = {
          index: this.languageDataSource.data.length + 1,
          language: this.languageForm?.get('language')?.value,
          resume: this.languageForm?.get('resume')?.value,
          shortDescription: this.languageForm?.get('shortDescription')?.value,
          title: this.languageForm?.get('title')?.value
        };

        this.languageDataSource.data.push(newLanguageData);
        this.languageTable.renderRows();
      };

      this.executeTableAction(this.languageDataSource, this.languageTableSort, addLanguageFunction);
      this.onSubmit();
    }
    else {
      this._snackbarService.openStandardSnackBar('addLanguageData', 'Ok');
    }
  }


  setShippingFormToEdit(row: any) {
    $('#countryFormSelect').css('pointer-events', 'none');

    this.isUpdatingShippingCosts = true;
    this.selectedElement = row;
    const indexToUpdate = this.shippingTableDataSource.data.findIndex(x => x.index == row.index);
    const elementToUpdate = this.shippingTableDataSource.data[indexToUpdate];

    this.shippingForm?.get('index')?.setValue(elementToUpdate.index);
    this.shippingForm?.get('country')?.setValue(elementToUpdate.country);
    this.shippingForm?.get('weight')?.setValue(elementToUpdate.weight);
    this.shippingForm?.get('unit_weight')?.setValue(elementToUpdate.unit_weight);
    this.shippingForm?.get('length')?.setValue(elementToUpdate.length);
    this.shippingForm?.get('height')?.setValue(elementToUpdate.height);
    this.shippingForm?.get('width')?.setValue(elementToUpdate.width);
    this.shippingForm?.get('unit_sizes')?.setValue(elementToUpdate.unit_sizes);
    this.shippingForm?.get('price')?.setValue(elementToUpdate.price);

  }

  editLanguageData() {

    const selectedLanguageName = this.languageForm.value.language;
    const updatedElement = {
      index: this.selectedElement.index,
      shortDescription: this.languageForm.value.shortDescription,
      title: this.languageForm.value.title,
      resume: this.languageForm.value.resume,
      language: selectedLanguageName,
    };

    const index = this.languageDataSource.data.findIndex((element: any) => element.index === this.selectedElement.index);
    this.languageDataSource.data[index] = updatedElement;
    this.languageDataSource._updateChangeSubscription();
    this.selectedElement = null;
    this.languageForm.reset();
    this.isUpdatingLanguage = false;
    this.onSubmit();
  }

  editElementLanguage(row: any) {
    this.isUpdatingLanguage = true;
    this.selectedElement = row;
    this.selectedLanguage = this.validAvailableLanguages.find(x => x.languageCode === this.selectedElement.language.languageCode) || '';
    this.languageForm.patchValue({
      index: row.index,
      language: this.selectedLanguage,
      title: row.title,
      shortDescription: row.shortDescription,
      resume: row.resume,
    });
    this.languageForm.controls['language'].setValue(this.selectedLanguage);
  }

  editElementPrice(row: any) {

    $('#countryFormSelect').css('pointer-events', 'none');
    this.isUpdatingPrice = true;
    this.selectedElementPrice = row;
    this.selectedPrice = this.validAvailableCountries.find(x => x.countryInfo?.countryCode === this.selectedElementPrice?.country.countryInfo.countryCode) || '';
    this.priceForm.patchValue({
      // Inicializa los campos del formulario con los valores de la fila seleccionada
      index: row.index,
      country: this.selectedPrice,
      amount: row.amount,
      taxes: row.taxes,
      taxPercValue: row.taxPercentage,
      totalValue: row.totalValue,
    });

    this.priceForm.controls['country'].setValue(this.selectedPrice);
  }

  editPriceData1() {


    $('#countryFormSelect').css('pointer-events', '');
    this.formSubmitted = true;

    const isValidPriceForm = this.validateFormData(this.priceForm);

    if (!isValidPriceForm) {
      return;
    }

    const selectedPriceCountry = this.priceForm.value;

    const index = this.priceDataSource.data.findIndex((element: any) => element.index === this.selectedElementPrice.index);
    let elementToUpdate = { ...this.priceDataSource.data[index] };
    elementToUpdate.index = this.selectedElementPrice.index;
    elementToUpdate.amount = this.priceForm.value.amount;
    elementToUpdate.taxPercentage = this.priceForm.value.taxPercValue;
    elementToUpdate.totalValue = this.priceForm.value.totalValue;
    elementToUpdate.taxes = this.priceForm.value.taxes;
    elementToUpdate.country = this.validAvailableCountries.find(x => x.countryInfo.countryCode == selectedPriceCountry.country.countryInfo.countryCode);

    this.priceDataSource.data[index] = elementToUpdate;
    this.priceDataSource._updateChangeSubscription();
    this.selectedElementPrice = null;
    this.priceForm.reset();
    this.isUpdatingPrice = false;
    this.onSubmit();
    this.formSubmitted = false;
  }

  removeLanguageData(position: number) {
    const removeLanguageFunction = () => {
      const indexToDelete = this.languageDataSource.data.findIndex(x => x.index == position);
      this.languageDataSource.data.splice(indexToDelete, 1);
      this.languageTable.renderRows();
    };

    this.executeTableAction(this.languageDataSource, this.languageTableSort, removeLanguageFunction);
  }

  setLanguageFormToEdit(position: number) {
    this.isUpdatingLanguage = true;
    // 

    this.removeInvalidStyleFromForm(this.languageForm);

    const indexToUpdate = this.languageDataSource.data.findIndex(x => x.index == position);
    const elementToUpdate = this.languageDataSource.data[indexToUpdate];
    this.languageForm?.get('index')?.setValue(elementToUpdate.index);
    this.languageForm?.get('language')?.setValue(elementToUpdate.language);
    this.languageForm?.get('title')?.setValue(elementToUpdate.title);
    this.languageForm?.get('shortDescription')?.setValue(elementToUpdate.shortDescription);
    this.languageForm?.get('resume')?.setValue(elementToUpdate.resume);

    this.previousLanguage = elementToUpdate.language.languageCode;
  }

  discardLanguageEdit() {
    this.isUpdatingLanguage = false;

    this.languageForm?.get('index')?.setValue('');
    this.languageForm?.get('language')?.setValue('default');
    this.languageForm?.get('title')?.setValue('');
    this.languageForm?.get('shortDescription')?.setValue('');
    this.languageForm?.get('resume')?.setValue('');

    this.removeInvalidStyleFromForm(this.languageForm);
  }

  isLanguageFilled(languageCode: string) {
    return this.languageDataSource.data.some(x => {
      return x.language.languageCode === languageCode;
    });
  }

  addPriceData() {
    this.formSubmitted = true;
    const isValidPriceForm = this.validateFormData(this.priceForm);

    if (!isValidPriceForm) {
      return;
    }

    if (!this.isPriceCountryFilled(this.priceForm?.get('country')?.value.countryInfo.countryCode)) {
      const addPriceFunction = () => {
        const newPriceData: PriceTableContent = {
          index: this.priceDataSource.data.length,
          amount: this.priceForm?.get('amount')?.value,
          taxes: this.priceForm?.get('taxes')?.value,
          country: this.priceForm?.get('country')?.value,
          totalValue: this.priceForm?.get('totalValue')?.value,
          taxPercentage: this.priceForm?.get('taxPercValue')?.value
        };

        this.priceDataSource.data.push(newPriceData);
        this.priceTable.renderRows();

        // Limpiar campos
        this.priceForm?.get('index')?.setValue('');
        this.priceForm?.get('country')?.setValue('default');
        this.priceForm?.get('amount')?.setValue('');
        this.priceForm?.get('taxes')?.setValue('');
        this.priceForm?.get('totalValue')?.setValue('');
        this.priceForm?.get('taxPercValue')?.setValue('');
        this.removeInvalidStyleFromForm(this.priceForm);
        this.priceForm.markAsUntouched();
        this.formSubmitted = false;
      };

      this.executeTableAction(this.priceDataSource, this.priceTableSort, addPriceFunction);
      this.onSubmit();
    }
    else {
      this._snackbarService.openStandardSnackBar('addPriceData', 'Ok');
    }
  }


  addShippingCostsData() {
    this.formSubmitted = true;
    const isValidShippingCostsForm = this.validateFormData(this.shippingForm);

    if (!isValidShippingCostsForm) {

      return;
    }

    const addShippingCostsFunction = () => {
      const newShippingData: ShippingCostsTableContent = {
        index: this.shippingTableDataSource.data.length,
        country: this.shippingForm?.get('country')?.value,
        weight: this.shippingForm?.get('weight')?.value,
        unit_weight: this.shippingForm?.get('unit_weight')?.value,
        length: this.shippingForm?.get('length')?.value,
        height: this.shippingForm?.get('height')?.value,
        width: this.shippingForm?.get('width')?.value,
        unit_sizes: this.shippingForm?.get('unit_sizes')?.value,
        price: this.shippingForm?.get('price')?.value
      };
      this.shippingTableDataSource.data.push(newShippingData);
      this.shippingCostsTable.renderRows();

      this.shippingForm?.get('country')?.setValue('default');
      this.shippingForm?.get('weight')?.setValue('');
      this.shippingForm?.get('unit_weight')?.setValue('default');
      this.shippingForm?.get('length')?.setValue('');
      this.shippingForm?.get('height')?.setValue('');
      this.shippingForm?.get('width')?.setValue('');
      this.shippingForm?.get('unit_sizes')?.setValue('default');
      this.shippingForm?.get('price')?.setValue('');
      this.removeInvalidStyleFromForm(this.shippingForm);
      this.shippingForm.markAsUntouched();
      this.formSubmitted = false;
    };

    this.executeTableAction(this.shippingTableDataSource, this.shippingCostsTableSort, addShippingCostsFunction);
    this.onSubmit();
    this.isUpdatingShippingCosts = false;
  }

  removeSizeData(position: number) {
    const removeSizeFunction = () => {
      const indexToDelete = this.shippingTableDataSource.data.findIndex(x => x.index == position);
      this.shippingTableDataSource.data.splice(indexToDelete, 1);
      this.shippingCostsTable.renderRows();
    };

    this.executeTableAction(this.shippingTableDataSource, this.shippingCostsTableSort, removeSizeFunction);
  }

  /**
   * Editar Peso y Tamaño
   */
  editShippingCostsData() {
    const selectedShippingCountry = this.shippingForm.value;

    const updatedElement = {
      // index: this.selectedElement.index,
      // country: this.validAvailableCountries.find(x => x.countryInfo.countryCode == selectedShippingCountry.country.countryInfo.countryCode),
      // weight: this.selectedElement.weight,
      // unit_weight: this.selectedElement.unit_weight,
      // length: this.selectedElement.length,
      // height: this.selectedElement.height,
      // width: this.selectedElement.width,
      // unit_sizes: this.selectedElement.unit_sizes,
      // price: this.selectedElement.price

      index: this.selectedElement.index,
      weight: this.shippingForm?.get('weight')?.value,
      unit_weight: this.shippingForm?.get('unit_weight')?.value,
      length: this.shippingForm?.get('length')?.value,
      height: this.shippingForm?.get('height')?.value,
      width: this.shippingForm?.get('width')?.value,
      unit_sizes: this.shippingForm?.get('unit_sizes')?.value,
      price: this.shippingForm?.get('price')?.value,
      country: this.validAvailableCountries.find(x => x.countryInfo.countryCode == selectedShippingCountry.country.countryInfo.countryCode)
    };

    const index = this.shippingTableDataSource.data.findIndex((element: any) => element.index === this.selectedElement.index);
    this.shippingTableDataSource.data[index] = updatedElement;
    this.shippingTableDataSource._updateChangeSubscription();
    this.selectedElement = null;
    this.shippingForm.reset();
    this.isUpdatingShippingCosts = false;
    this.onSubmit();
  }



  discardShippingCostsData() {
    this.isUpdatingShippingCosts = false;

    this.shippingForm?.get('country')?.setValue('default');
    this.shippingForm?.get('weight')?.setValue('');
    this.shippingForm?.get('unit_weight')?.setValue('default');
    this.shippingForm?.get('length')?.setValue('');
    this.shippingForm?.get('height')?.setValue('');
    this.shippingForm?.get('width')?.setValue('');
    this.shippingForm?.get('unit_sizes')?.setValue('default');
    this.shippingForm?.get('price')?.setValue('');
    this.removeInvalidStyleFromForm(this.shippingForm);
    this.shippingForm.markAsUntouched();
  }


  editElementSize(row: any) {
    $('#countryFormSelect').css('pointer-events', 'none');
    this.formSubmitted = true;
    this.selectedElementSize = row;
    this.selectedPrice = this.validAvailableCountries.find(x => x.countryInfo?.countryCode === this.selectedElementSize?.country.countryInfo.countryCode) || '';
    this.priceForm.patchValue({
      index: row.index,
      country: this.selectedPrice,
      amount: row.amount,
      taxes: row.taxes,
      height: row.height,
      length: row.length,
      unit_sizes: row.unit_sizes,
      unit_weight: row.unit_weight,
      weight: row.weight,
      width: row.width,
      price: row.price
    });

    this.priceForm.controls['country'].setValue(this.selectedPrice);
  }

  editPriceData() {
    const isValidPriceForm = this.validateFormData(this.priceForm);
    if (!isValidPriceForm)
      return;

    const selectedCountryCode = this.priceForm?.get('country')?.value.countryInfo.countryCode;

    const isValidEdit = !this.isPriceCountryFilled(selectedCountryCode) ||
      this.previousPriceCountry.countryInfo.countryCode == selectedCountryCode;

    if (isValidEdit) {
      const editPriceFunction = () => {
        const indexToUpdate = this.priceForm?.get('position')?.value;

        this.priceDataSource.data[indexToUpdate].amount = this.priceForm?.get('amount')?.value;
        this.priceDataSource.data[indexToUpdate].taxes = this.priceForm?.get('taxes')?.value;
        this.priceDataSource.data[indexToUpdate].country = this.priceForm?.get('country')?.value;

        this.discardPriceEdit();
      };

      this.executeTableAction(this.priceDataSource, this.priceTableSort, editPriceFunction);
    }
    else {
      this._snackbarService.openStandardSnackBar('editPriceData', 'Ok');
    }
  }

  setPriceFormToEdit(position: number) {
    this.isUpdatingPrice = true;
    this.removeInvalidStyleFromForm(this.priceForm);

    const indexToUpdate = this.priceDataSource.data.findIndex(x => x.index == position);
    const elementToUpdate = this.priceDataSource.data[indexToUpdate];
    this.priceForm?.get('position')?.setValue(elementToUpdate.index);
    this.priceForm?.get('country')?.setValue(elementToUpdate.country);
    this.priceForm?.get('amount')?.setValue(elementToUpdate.amount);
    this.priceForm?.get('taxes')?.setValue(elementToUpdate.taxes);

    this.previousPriceCountry = elementToUpdate.country;
  }

  discardPriceEdit() {
    $('#countryFormSelect').css('pointer-events', '');
    this.isUpdatingPrice = false;

    this.priceForm?.get('position')?.setValue('');
    this.priceForm?.get('country')?.setValue('default');
    this.priceForm?.get('amount')?.setValue('');
    this.priceForm?.get('taxes')?.setValue('');

    this.removeInvalidStyleFromForm(this.priceForm);

    this.priceForm.markAsUntouched();
    this.formSubmitted = false;
  }

  removePriceData(position: number) {
    const removePriceFunction = () => {
      const indexToDelete = this.priceDataSource.data.findIndex(x => x.index == position);
      this.priceDataSource.data.splice(indexToDelete, 1);
      this.priceTable.renderRows();
    };

    this.executeTableAction(this.priceDataSource, this.priceTableSort, removePriceFunction);
  }

  isPriceCountryFilled(countryCode: string) {
    return this.priceDataSource.data.some(x => {
      return x.country.countryInfo.countryCode === countryCode;
    });
  }

  getTotalCost(element: any): number {
    return parseFloat(element.amount) + parseFloat(element.taxes);
  }

  private executeTableAction(dataSource: MatTableDataSource<any>, dataSort: MatSort, action: any) {
    /*Reason: The table wasn't refreshing when data source changing. It was caused by sort. By this reson we should
      disable the sort before update the table info, and before enable the sort again
    */
    this.disableDataSort(dataSource);

    action();

    this.enableDataSort(dataSource, dataSort);
  }

  private enableDataSort(dataSource: MatTableDataSource<any>, dataSort: MatSort) {
    dataSource.sort = dataSort;
  }

  private disableDataSort(dataSource: MatTableDataSource<any>) {
    dataSource.sort = null;
  }

  validateFormData(formGroup: FormGroup) {
    Object.entries(formGroup.controls).forEach(([key, value]) => {

      const controlElement = document.querySelector(`[formControlName=${key}]`);

      if ((value as AbstractControl).status == 'INVALID')
        controlElement?.classList.add('is-invalid');
      else
        controlElement?.classList.remove('is-invalid');
    });

    // 

    return formGroup.status == 'VALID';
  }

  removeInvalidStyleFromForm(formGroup: FormGroup) {
    Object.entries(formGroup.controls).forEach(([key, value]) => {

      const controlElement = document.querySelector(`[formControlName=${key}]`);
      controlElement?.classList.remove('is-invalid');
    });
  }

  /**
   * We prepare formData for send update of general info. 
   * @returns 
   */
  generateFormDataGeneralInfo() {
    let requestFormData = new FormData();
    requestFormData.append('coverImage', this.productForm.get('coverImageSource')?.value);
    requestFormData.append('content', this.productForm.get('contentSource')?.value);

    requestFormData.set('interactiveCode', this.productForm.get('interactiveCode')?.value);
    requestFormData.set('bookLink', this.productForm.get('bookLink')?.value);
    requestFormData.set('availability', this.productForm.get('availability')?.value);
    requestFormData.set('isbn', this.productForm.get('isbn')?.value);
    requestFormData.set('selectedCategories', this.productForm.get('selectedCategories')?.value);
    requestFormData.set('productId', this.dataProduct.product.productId.toString());
    requestFormData.set('editorialBookId', this.productForm.get('editorialBook')?.value);

    //requestFormData.set('editorialBook', this.dataProduct.product..toString());
    //console.log('datos de editorialBook:', requestFormData)

    this.onSubmit()
    return requestFormData;

  }

  generateFormdataForRequest() {
    let requestFormData = new FormData();
    let bookLanguages: { title: string; shortDescription: string; resume: string; languageCode: any; }[] = [];
    let bookPrices: { amount: number; taxes: number; country: any; currency: string; taxPercentage: number; totalValue: number; }[] = [];
    let bookShippingCost: { country: any; weight: number; length: number; height: number; width: number; price: number; unit_weight: string; unit_sizes: string }[] = [];

    this.languageDataSource.data.forEach(lang => {
      bookLanguages.push({
        title: lang.title,
        shortDescription: lang.shortDescription,
        resume: lang.resume,
        languageCode: lang.language.languageCode
      });
    });

    // 

    this.priceDataSource.data.forEach(amount => {
      if (amount.country.countryInfo != undefined) {
        bookPrices.push({
          amount: amount.amount,
          taxes: amount.taxes,
          country: amount.country.countryInfo.countryCode,
          currency: amount.country.currency.name,
          taxPercentage: amount.taxPercentage,
          totalValue: amount.totalValue
        });
      } else {
        bookPrices.push({
          amount: amount.amount,
          taxes: amount.taxes,
          country: amount.country.countryCode,
          currency: amount.country.currency.name,
          taxPercentage: amount.taxPercentage,
          totalValue: amount.totalValue
        });
      }
    });

    this.shippingTableDataSource.data.forEach(item => {
      bookShippingCost.push({
        country: item.country,
        weight: item.weight,
        length: item.length,
        height: item.height,
        width: item.width,
        price: item.price,
        unit_weight: item.unit_weight,
        unit_sizes: item.unit_sizes
      });

    });

    requestFormData.append('coverImage', this.productForm.get('coverImageSource')?.value);
    requestFormData.append('content', this.productForm.get('contentSource')?.value);
    requestFormData.set('interactiveCode', this.productForm.get('interactiveCode')?.value);
    requestFormData.set('bookLink', this.productForm.get('bookLink')?.value);
    requestFormData.set('productCode', this.productForm.get('productCode')?.value);
    requestFormData.set('productId', this.productForm.get('productId')?.value);
    requestFormData.set('editorialId', `${this.dataProduct.editorialId}`);
    requestFormData.set('availability', this.productForm.get('availability')?.value);
    requestFormData.set('isbn', this.productForm.get('isbn')?.value);
    requestFormData.set('weight', this.productForm.get('weight')?.value);
    requestFormData.set('licenseType', this.productForm.get('licenseType')?.value);
    requestFormData.set('selectedCategories', this.productForm.get('selectedCategories')?.value);
    requestFormData.set('isPreview', this.productForm.get('isPreview')?.value);
    requestFormData.set('uniqueMagazineCode', this.productForm.get('uniqueMagazineCode')?.value);
    requestFormData.set('type', this.productForm.get('type')?.value);
    requestFormData.set('languages', JSON.stringify(bookLanguages));
    requestFormData.set('prices', JSON.stringify(bookPrices));
    requestFormData.set('shipping_cost', JSON.stringify(bookShippingCost));

    requestFormData.set('height', this.productForm.get('height')?.value);
    requestFormData.set('length', this.productForm.get('length')?.value);
    requestFormData.set('unit_sizes', this.productForm.get('unit_sizes')?.value);
    requestFormData.set('unit_weight', this.productForm.get('unit_weight')?.value);
    requestFormData.set('weight', this.productForm.get('weight')?.value);
    requestFormData.set('width', this.productForm.get('width')?.value);




    return requestFormData;
  }

  validateLanguagesData() {
    let isSomeLanguageNotContained = false;

    this.validAvailableLanguages.forEach((validElement: any) => {
      this.languageDataSource.data.some((sourceElement) => {
        if (validElement.languageCode != sourceElement.language.languageCode)
          isSomeLanguageNotContained = true;
      });
    });

    return isSomeLanguageNotContained;
  }

  onSubmit() {
    this._pageLoadingService.showLoadingGif();

    if (!this.productForm.controls['type'].value) {
      this.productForm.controls['type'].setValue("Book");
    }

    if (!this.validateFormData(this.productForm)) {
      if (!this.validateLanguagesData()) {
        this._snackbarService.openStandardSnackBar('onSubmitInvalidFormData');
      }
      this._pageLoadingService.hideLoadingGif();
      return;
    } else {
      if (!this.productForm.get('uniqueMagazineCode')?.value) {
        this.productForm.get('uniqueMagazineCode')?.setValue(null);
      }

      //console.log('Valor del campo type:', this.productForm.get('type')?.value);
      //console.log('Valor de uniqueMagazineCode antes de la solicitud:', this.productForm.get('uniqueMagazineCode')?.value);

      this._editorialService.updateProduct(this.generateFormdataForRequest()).subscribe(
        (data) => {
          this._pageLoadingService.hideLoadingGif();
          this._snackbarService.openStandardSnackBar('onSubmitSuccessfullyUpdated', 'Ok');
        },
        (error) => {
          this._pageLoadingService.hideLoadingGif();
          console.error('Error al actualizar el producto:', error);
          this._snackbarService.openStandardSnackBar('onSubmitError', 'Ok');
        }
      );
    }

    //console.log(this.generateFormdataForRequest());
  }

  submitGeneralInfo() {
    this._pageLoadingService.showLoadingGif();
    this._editorialService.updateGeneralInfo(this.generateFormDataGeneralInfo()).subscribe((data) => {
      //console.log('updateGeneralInfo:', data);
      this._pageLoadingService.hideLoadingGif();
      this._snackbarService.openStandardSnackBar('onSubmitSuccessfullyUpdated', 'Ok');
    },);
  }

}
