import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'src/app/auth/services/logIn.service';
import { environment as env } from 'src/environments/environment';
import { PageLoadingService } from '../../services/page-loading.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  applicationLanguages: string[] = env.languages_supported;
  selectedLanguage: string;

  constructor(private _translate: TranslateService,
    private _loginService : LoginService) { }

  ngOnInit(): void {
    this.selectedLanguage = this._translate.currentLang;
  }

  selectLang(event: any, lang:any) {
    this._translate.use(lang);
    // console.log(lang);
    this.selectedLanguage = lang;

    event.preventDefault();
  }

  isLogged() {
    return this._loginService.isLogged();
  }

}
